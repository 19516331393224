import React, { Component } from 'react'
import { withStyles, Button, Grid, TextField, Chip } from '@material-ui/core';
import { PostFormData, DeleteData } from '../../api/service';


const styles = theme => ({
    textField: {
        width: '100%'
    },
    button: {
        marginTop: '13px'
    }
})

class FileUpload extends Component {
    constructor(props) {
        super(props);

        this.state = {
            type: '',
            filename: '',
            file: '',
            errors: {},
            dp: []
        }

        this.onChange = this.onChange.bind(this);
        this.addFile = this.addFile.bind(this);
        this.fileHandle = this.fileHandle.bind(this);


    }
    __initialise() {
        this.setState({
            type: this.props.type,
            filename: '',
            file: '',
            errors: {}
        })
    }

    componentDidMount() {
        this.__initialise();

        this.setState({
            dp: this.props.dp,
        })

    }

    onChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    fileHandle(e) {
        this.setState({
            filename: e.target.value,
            file: e.target.files[0]
        })
    }

    handleValidation() {
        let { type, filename } = this.state;
        let isValid = true;

        let errors = {}

        if (type !== '') {
            isValid = true;
            errors['type'] = "";
        } else {
            isValid = false;
            errors['type'] = "Cannot be empty";
        }

        if (filename !== '') {
            isValid = true;
            errors['filename'] = "";
        } else {
            isValid = false;
            errors['filename'] = "Cannot be empty";
        }


        this.setState({
            errors
        })

        return isValid;


    }

    addFile() {
        if (this.handleValidation) {
            let formdata = new FormData();
            formdata.append('type', this.state.type);
            formdata.append('image_file', this.state.file);

            PostFormData(`/${this.props.apikey}/addfile`, formdata)
                .then((resp) => {
                    //console.log(resp);
                    if (resp !== false) {
                        if (resp.type === this.props.type) {
                            let dp = this.state.dp;
                            dp.push(resp);
                            this.props.setDp(dp);
                            this.setState({
                                dp: dp
                            })
                        }
                    }

                    this.__initialise();
                })
        }
    }



    onDpDelete(el,index) {
        let dp = this.state.dp;
        dp.splice(index, 1);
        DeleteData(`/${this.props.apikey}/${el.type}/${el.path}/deletefile`)
        .then((resp) => {
            if(resp === true){
                this.props.setDp(dp);
                this.setState({
                    dp
                })
            }
        })
        
    }




    render() {
        let { classes } = this.props;
        let d = [];
        let dp = this.state.dp;
        if (Array.isArray(dp) && dp.length > 0) {
            //console.log(dp)
            d = dp.map((el, index) =>
                <Chip
                    key={index}
                    label={el.path}
                    onDelete={this.onDpDelete.bind(this, el, index)}
                />
            )

        }
        return (
            <div>
                <Grid container>
                    <Grid item xs={8} lg={10}>
                        <TextField
                            name="filename"
                            type="file"
                            margin="normal"
                            className={classes.textField}
                            InputLabelProps={{
                                shrink: true
                            }}
                            value={this.state.filename}
                            onChange={this.fileHandle}
                        />
                    </Grid>
                    <Grid item xs={2} lg={2}>
                        <Button
                            variant="contained"
                            className={classes.button}
                            onClick={this.addFile}
                        >
                            Upload
                        </Button>
                    </Grid>

                    <Grid item xs={12} lg={12}>
                        {d}
                    </Grid>
                </Grid>
            </div>
        )
    }
}

export default withStyles(styles)(FileUpload);