import React, { Component } from 'react';
import { Table, TableHead, TableRow, TableCell, TableBody } from '@material-ui/core';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import moment from 'moment';
class ActivityTable extends Component {
    
    render() {
        return (
            <div>
                <div align="center">
                    <ReactHTMLTableToExcel
                        id="test-table-xls-button"
                        className="download-table-xls-button"
                        table="table-to-xls"
                        filename={`My Activity List`}
                        sheet="tablexls"
                        buttonText="Download as XLS" />

                </div>


                <Table size="small" id="table-to-xls" style={{backgroundColor: 'white', padding: '10px'}} className="table-bordered">
                    <TableHead>
                        <TableRow>
                            <TableCell >#</TableCell>
                            <TableCell >Activity Name</TableCell>
                            <TableCell >Title</TableCell>
                            <TableCell >Description</TableCell>
                            <TableCell >Start Date</TableCell>
                            <TableCell >End Date</TableCell>
                            <TableCell >Entry Date</TableCell>
                            

                        </TableRow>
                       
                    </TableHead>
                    <TableBody>
                        {this.props.my_activities.length>0 && this.props.my_activities.map((el, index) =>

                            <TableRow key={index}>
                                <TableCell>{index+1}</TableCell>
                               <TableCell>{el.activity_name}</TableCell>
                               <TableCell>{el.activity_title}</TableCell>
                               <TableCell>{el.description}</TableCell>
                               <TableCell>{moment(el.event_date).format('DD-MM-YYYY')}</TableCell>
                               <TableCell>{moment(el.end_date).format('DD-MM-YYYY')}</TableCell>
                               <TableCell>{el.entry_date}</TableCell>
                            </TableRow>

                        )}
                    </TableBody>
                </Table>
            </div>
        );
    }
}

export default ActivityTable;