import React, { Component } from 'react'
import { withStyles } from '@material-ui/core';

import CurricularActivityLanding from '../../Activities/CurricularActivityLanding';

const styles = theme => ({
   
})

class StudentCoCurricularLanding extends Component {
    render() {
        let { classes } = this.props;
        return (
            <div>
                <CurricularActivityLanding
                    history={this.props.history}
                    {...this.props}
                />
            </div>
        )
    }
}

export default withStyles(styles)(StudentCoCurricularLanding)