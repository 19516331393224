import React, { Component } from 'react'
import { withStyles, Button, Card, CardContent, CardHeader, Avatar, CardActions, Typography, CircularProgress, Grid } from '@material-ui/core';
import { blue, orange, grey } from '@material-ui/core/colors';
import PreviewForm from '../View/PreviewForm';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import CallToAction from '@material-ui/icons/CallToAction'
import { GetData } from '../../../api/service';
import AlertDiv from './AlertDiv';
import Loading from './Loading';
import FileUpload from './FileUpload';


const styles = theme => ({
    buttonSubmit: {
        color: 'white',
        backgroundColor: blue[400],
        '&:hover': {
            color: blue[400],
            backgroundColor: 'white',
            border: `solid thin ${blue[400]}`
        }
    },
    cardActions: {
        justifyContent: 'flex-end'
    },
    redStrip: {
        width: '100%',
        height: 3,
        backgroundColor: '#17408C'
    }
})

class PaymentGo extends Component {

    state = {
        step: 1,
        admission_receipt:[],
        gu_exam_form: [],
        gu_fees_receipt: [],
        college_fees_receipt: [],
        email: this.props.user.email,
        errors: [],
        isLoaded: false
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        //console.log(this.props.user)

        let data = this.props.data;
        if (Array.isArray(data) && data.length > 0) {
            this.setState({
                ...data[0]
            })
        }

    }

    onChangePhoto = (name, value) => {
        this.setState({
            [name]: value
        })
    }

    handleValidation = () => {
        //console.log(this.state)
        let { course, gu_exam_form, gu_fees_receipt, college_fees_receipt, errors } = this.state;
        let isValid = true;
        // if (course == 'BA' || course == 'BSC' || course == 'BBA' || course == 'BSCIT' || course == 'PG') {
        //     if (Array.isArray(gu_exam_form) && gu_exam_form.length === 0) {
        //         isValid = false;
        //         errors['gu_exam_form'] = 'Please fill up this field'
        //     } else {
        //         errors['gu_exam_form'] = '';
        //     }
        //     if (Array.isArray(gu_fees_receipt) && gu_fees_receipt.length === 0) {
        //         isValid = false;
        //         errors['gu_fees_receipt'] = 'Please fill up this field'
        //     } else {
        //         errors['gu_fees_receipt'] = '';
        //     }
        //     if (Array.isArray(college_fees_receipt) && college_fees_receipt.length === 0) {
        //         isValid = false;
        //         errors['college_fees_receipt'] = 'Please fill up this field'
        //     } else {
        //         errors['college_fees_receipt'] = '';
        //     }
        // }
        
        return isValid;
    }


    onSubmit = async (e) => {
        e.preventDefault();

        if (this.handleValidation()) {

            confirmAlert({
                title: 'Confirm to submit',
                message: 'Are you sure to do this ? This is the final step. You can not modify later.',
                buttons: [
                    {
                        label: 'Yes',
                        onClick: () => this.props.setData(this.state)
                    },
                    {
                        label: 'No'
                    }
                ]
            });
        }
    }



    render() {
        let { classes } = this.props;

        return (
            <div>
                <form onSubmit={this.onSubmit}>

                    <Card>
                        <CardHeader

                            title={`Documents Upload`}

                        />

                        <CardContent>


                            <AlertDiv
                                back={orange[100]}
                                border={orange[400]}
                            >
                                <Typography
                                    variant="body2"
                                >
                                    Please make the payment (if applicable) and then upload documents.
                                </Typography>
                                <ul>
                                    
                                    <li>
                                        <Typography>
                                            Account details are provided in the exam notice.
                                        </Typography>
                                    </li>
                                    <li>
                                        <Typography>
                                            You can pay using UPI , Net Banking , NEFT , RTGS.
                                        </Typography>
                                    </li>
                                    {this.state.total_fees != '0' && <li>
                                        <Typography>
                                            Please take a screenshot or printout of the transaction.You need to upload it.
                                        </Typography>
                                    </li>}
                                    <li>
                                        <Typography>
                                            You can print and save the Online application form after the payment.
                                        </Typography>
                                    </li>


                                </ul>
                            </AlertDiv>

                            <Grid item xs={12} sm={12} lg={6} md={6}>
                                    {/* {this.state.errors['gu_exam_form'] !== '' ? (

                                        <div>
                                            <Typography
                                                variant="caption"
                                                style={{ color: 'red' }}
                                            >
                                                {this.state.errors['gu_exam_form']}
                                            </Typography>
                                        </div>

                                    ) : null} */}

                                    <FileUpload
                                        title={
                                            <Typography
                                                variant="subtitle2"
                                                style={{ fontWeight: 700, fontSize: '12px' }}
                                            >
                                                Upload Admission Receipt (size below 500kb)
                                            </Typography>
                                        }
                                        type={`ADMISSION_RECEIPT`}
                                        apikey={this.props.user.apai_key}
                                        onChange={this.onChangePhoto.bind(this, 'admission_receipt')}
                                        data={this.state.admission_receipt}
                                        limit={500}
                                    />


                                </Grid>

                            {/* {(this.state.course === 'BA' || this.state.course === 'BSC' || this.state.course === 'BBA' || this.state.course === 'BSCIT' || this.state.course === 'PG') && <Grid container spacing={24}>
                                <Grid item xs={12} sm={12} lg={6} md={6}>
                                    {this.state.errors['gu_exam_form'] !== '' ? (

                                        <div>
                                            <Typography
                                                variant="caption"
                                                style={{ color: 'red' }}
                                            >
                                                {this.state.errors['gu_exam_form']}
                                            </Typography>
                                        </div>

                                    ) : null}

                                    <FileUpload
                                        title={
                                            <Typography
                                                variant="subtitle2"
                                                style={{ fontWeight: 700, fontSize: '12px' }}
                                            >
                                                Upload G.U Exam Form (size below 500kb)<span className="red-alert">*</span>
                                            </Typography>
                                        }
                                        type={`GU_EXAM_FORM`}
                                        apikey={this.props.user.apai_key}
                                        onChange={this.onChangePhoto.bind(this, 'gu_exam_form')}
                                        data={this.state.gu_exam_form}
                                        limit={500}
                                    />


                                </Grid>

                                <Grid item xs={12} sm={12} lg={6} md={6}>
                                    {this.state.errors['gu_fees_receipt'] !== '' ? (

                                        <div>
                                            <Typography
                                                variant="caption"
                                                style={{ color: 'red' }}
                                            >
                                                {this.state.errors['gu_fees_receipt']}
                                            </Typography>
                                        </div>

                                    ) : null}

                                    <FileUpload
                                        title={
                                            <Typography
                                                variant="subtitle2"
                                                style={{ fontWeight: 700, fontSize: '12px' }}
                                            >
                                                G.U Fees Receipt (size below 500kb)<span className="red-alert">*</span>
                                            </Typography>
                                        }
                                        type={`GU_FEES_RECEIPT`}
                                        apikey={this.props.user.apai_key}
                                        onChange={this.onChangePhoto.bind(this, 'gu_fees_receipt')}
                                        data={this.state.gu_fees_receipt}
                                        limit={500}
                                    />

                                </Grid>


                                <Grid item xs={12} sm={12} lg={6} md={6}>
                                    {this.state.errors['college_fees_receipt'] !== '' ? (

                                        <div>
                                            <Typography
                                                variant="caption"
                                                style={{ color: 'red' }}
                                            >
                                                {this.state.errors['college_fees_receipt']}
                                            </Typography>
                                        </div>

                                    ) : null}

                                    <FileUpload
                                        title={
                                            <Typography
                                                variant="subtitle2"
                                                style={{ fontWeight: 700, fontSize: '12px' }}
                                            >
                                                College Fees Receipt (size below 500kb)<span className="red-alert">*</span>
                                            </Typography>
                                        }
                                        type={`COLLEGE_FEES_RECEIPT`}
                                        apikey={this.props.user.apai_key}
                                        onChange={this.onChangePhoto.bind(this, 'college_fees_receipt')}
                                        data={this.state.college_fees_receipt}
                                        limit={500}
                                    />

                                </Grid>

                            </Grid>} */}

                        </CardContent>

                        <br />
                        <CardActions className={classes.cardActions}>

                            <Button

                                type="button"
                                onClick={this.props.back}
                            >
                                Previous
                                </Button>

                            <Button
                                variant="contained"
                                className={classes.buttonSubmit}
                                type="submit"
                            >
                                Final Submit
                            </Button>

                        </CardActions>

                    </Card>
                </form>
            </div>
        )
    }
}

export default withStyles(styles)(PaymentGo)