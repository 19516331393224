import React, { Component } from 'react'
import { withStyles, Grid, Card, CardHeader, Typography, Avatar, CardContent, TextField, Button, CardActions, CircularProgress, Chip, Divider } from '@material-ui/core';
import Person from '@material-ui/icons/Person'
import Done from '@material-ui/icons/Done'
import { red, lightGreen, blue, orange } from '@material-ui/core/colors';
import { PostData } from '../../../api/service';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import AlertDiv from '../Forms/AlertDiv';


const styles = theme => ({
    avatar: {
        backgroundColor: 'red'
    },
    textField: {

    },
    otpButton: {
        height: theme.spacing.unit * 7,
        backgroundColor: lightGreen[500],
        color: 'white',
        '&:hover': {
            color: lightGreen[500],
            backgroundColor: 'white'
        }
    },
    cardActions: {
        justifyContent: 'flex-end'
    },
    submitButton: {
        backgroundColor: 'blue',
        color: 'white'
    },
    otpDiv: {
        height: theme.spacing.unit * 7,
        display: 'flex',
        alignItems: 'center',
        color: blue[300]
    },
    iconOtp: {
        fontSize: 15,
        marginRight: '10px'
    }
})

class UsernameRegistration extends Component {

    state = {
        name: '',
        date_of_birth: '',
        email: '',
        phone: '',
        password: '',
        confirm_password: '',
        errors: [],
        isLoading: false,
        message: '',
        error: '',
        otp: '',
        is_opt_sent: false,
        message: '',
        is_otp_resent: false,
    }

    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    handleValidation = () => {

        let isValid = false;
        let { name, email, phone, date_of_birth, password, confirm_password, otp } = this.state;
        if (password !== confirm_password) {
            isValid = false;
            this.setState({
                error: 'Password do not match .'
            })
            return isValid;
        }

        if (name !== '' && email !== '' && phone !== '' && date_of_birth !== '' && otp !== '') {
            isValid = true;

            let error = 'Please provide all the mandatory fields';
            this.setState({
                error
            })
            return isValid;
        }
    }

    onSubmit = (e) => {
        e.preventDefault();

        let errors = this.state.errors;
        //console.log(this.handleValidation())

        if (this.handleValidation() === true) {


            let data = {
                name: this.state.name,
                date_of_birth: this.state.date_of_birth,
                email: this.state.email,
                phone: this.state.phone,
                otp: this.state.otp,
                password: this.state.password,
            }

            PostData(`/examform/signup`, data)
                .then((resp) => {
                    if (resp.status) {

                        let login_data = {
                            email: this.state.email,
                            password: this.state.password
                        }

                        confirmAlert({
                            title: 'Registration Successful.Note down your credential.',
                            message: `Your email is : ${this.state.email} and password is : ${this.state.password}`,
                            buttons: [
                                {
                                    label: 'Okay',
                                    onClick: () => this.props.history.push('/', login_data)
                                }
                            ]
                        });
                    } else {
                        confirmAlert({
                            title: 'Registration Not - Successful',
                            message: resp.msg,
                            buttons: [
                                {
                                    label: 'Okay'
                                }
                            ]
                        });
                    }
                })

        } else {


        }




    }

    inputVerification = (type, value) => {
        if (type === 'phone') {
            let patt = /^([6-9][0-9]{9})$/;
            return patt.test(value);
        }

        if (type === 'email') {
            let patt = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return patt.test(value);
        }
    }



    sendOtp = () => {
        let phone = this.state.phone;
        let email = this.state.email;
        let errors = this.state.errors;

        let flag = 0;

        if (this.inputVerification('phone', phone)) {
            flag = flag + 1;
            errors['phone'] = '';
        } else {
            errors['phone'] = 'Phone number not valid'
        }


        if (this.inputVerification('email', email)) {
            flag = flag + 1;
            errors['email'] = '';
        } else {
            errors['email'] = 'Email not valid'
        }
        console.log(flag);
        if (flag === 2) {
            let data = {
                email: email,
                phone: phone
            }

            this.setState({
                isSubmitting: true
            })
            PostData(`/examotp`, data)
                .then((resp) => {
                    this.setState({
                        isSubmitting: false
                    })
                    this.setState({
                        is_opt_sent: resp.status,
                        message: resp.status_text,
                        otp: resp.otp
                    })
                }).catch((err) => {
                    this.setState({
                        isSubmitting: false
                    })
                })
        }


        this.setState({
            errors
        })




    }

    resend_otp = () => {
        this.setState({
            is_otp_resent: true
        }, () => {
            let d = {
                phone: this.state.phone,
                email: this.state.phone
            }

            PostData(`/resendexamotp`, d)
                .then((resp) => {
                    this.setState({
                        message: resp.status_text
                    })
                })
        })
    }


    render() {
        let { classes } = this.props;
        return (
            <div>

                <form onSubmit={this.onSubmit}>
                    <Card>

                        <CardHeader

                            title={
                                <div>
                                    <Typography
                                        variant="h6"
                                    >
                                        New Registration
                                    </Typography>
                                    <Typography
                                        variant="caption"
                                    >
                                        Please sign up here
                                    </Typography>
                                </div>
                            }

                        />
                        <Divider />

                        <CardContent>
                            {this.state.error !== '' && <Typography>{this.state.error}<br /><br /></Typography>}
                            <Grid container spacing={24}>

                                <Grid item xs={12} md={4} lg={4} sm={12}>

                                    <TextField
                                        variant="outlined"
                                        className={classes.textField}
                                        fullWidth
                                        required
                                        label="Applicant Name"
                                        name="name"
                                        value={this.state.name}
                                        onChange={this.onChange}
                                    />

                                </Grid>


                                <Grid item xs={12} md={4} lg={4} sm={12}>

                                    <TextField
                                        type="date"
                                        variant="outlined"
                                        className={classes.textField}
                                        fullWidth
                                        required
                                        label="Date Of Birth"
                                        name="date_of_birth"
                                        value={this.state.date_of_birth}
                                        onChange={this.onChange}
                                        InputLabelProps={{ shrink: true }}
                                    />

                                </Grid>

                                <Grid item xs={12} md={4} lg={4} sm={12}>

                                    <TextField
                                        variant="outlined"
                                        className={classes.textField}
                                        fullWidth
                                        required
                                        type="email"
                                        label="Applicant Email Id"
                                        name="email"
                                        value={this.state.email}
                                        onChange={this.onChange}
                                        error={Boolean(this.state.errors['email'])}
                                        disabled={this.state.is_opt_sent}
                                    />

                                </Grid>

                                <Grid item xs={12} md={8} lg={8} sm={12}>

                                    <TextField
                                        variant="outlined"
                                        className={classes.textField}
                                        fullWidth
                                        required
                                        type="number"
                                        label="Applicant Phone Number"
                                        name="phone"
                                        value={this.state.phone}
                                        onChange={this.onChange}
                                        error={Boolean(this.state.errors['phone'])}
                                        helperText={`10 digit number, do not write +91 or 0 at the begining`}
                                        disabled={this.state.is_opt_sent}
                                        // disabled={this.state.is_opt_sent}
                                    />

                                </Grid>

                                <Grid item xs={12} md={4} lg={4} sm={12}>

                                    {!this.state.is_opt_sent ? (
                                        <span>
                                            {this.state.isLoading ? (
                                                <div style={{ display: 'flex', alignItems: 'center', paddingTop: '10px' }}>
                                                    <CircularProgress
                                                        size={24}
                                                    /> &nbsp; <Typography>Generating...Please wait</Typography>
                                                </div>
                                            ) : (<Button
                                                variant="contained"
                                                className={classes.otpButton}
                                                type="button"
                                                onClick={this.sendOtp}
                                            >
                                                Generate OTP
                                            </Button>)}

                                        </span>
                                    ) :  <div className={classes.otpDiv}>
                                    <Typography
                                        color="inherit"
                                    >
                                        <Done className={classes.iconOtp} /> {this.state.message} {!this.state.is_otp_resent ? <Button onClick={this.resend_otp}>Resent OTP</Button> : null}
                                    </Typography>
                                </div>
                                       
                                    }

                                </Grid>


                                <Grid item xs={12} md={12} lg={12} sm={12}>

                                    <TextField
                                        variant="outlined"
                                        className={classes.textField}
                                        required
                                        
                                        type="text"
                                        label="Enter OTP"
                                        name="otp"
                                        value={this.state.otp}
                                        onChange={this.onChange}
                                        helperText={`6 digit OTP`}
                                    />

                                </Grid>


                                <Grid item xs={12} md={4} lg={4} sm={12}>

                                    <TextField
                                        variant="outlined"
                                        className={classes.textField}
                                        fullWidth
                                        required
                                        type="password"
                                        label="Password"
                                        name="password"
                                        value={this.state.password}
                                        onChange={this.onChange}
                                    />

                                </Grid>

                                <Grid item xs={12} md={4} lg={4} sm={12}>

                                    <TextField
                                        variant="outlined"
                                        className={classes.textField}
                                        fullWidth
                                        required
                                        type="password"
                                        label="Confirm Password"
                                        name="confirm_password"
                                        error={Boolean(this.state.errors['password'])}
                                        value={this.state.confirm_password}
                                        onChange={this.onChange}
                                        helperText={this.state.errors['password']}
                                    />

                                </Grid>

                            </Grid>

                        </CardContent>

                        <Divider />

                        <CardActions className={classes.cardActions}>
                            <Button
                                variant="contained"
                                type="submit"
                                className={classes.submitButton}
                            >

                                Submit

                            </Button>

                        </CardActions>

                    </Card>
                </form>
            </div>
        )
    }
}

export default withStyles(styles)(UsernameRegistration)
