import React, { Component } from "react";
import {
  withStyles,
  Grid,
  Card,
  CardHeader,
  Typography,
  TextField,
  Button,
  CardActions,
  Divider,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import { red } from "@material-ui/core/colors";
import { withTheme } from "@material-ui/core/styles";
import { GetData, PostData } from "../../../../api/service";
import { message } from "antd";
import StateSelect from "../StateSelect";
import States from '../../../../Helper/IndianStates.json'
import DistrictSelect from "../DistrictSelect";

const styles = (theme) => ({
  formControl: {
    // margin: theme.spacing(2, 0),
    margin: "10px",
    minWidth: 120,
  },
  // selectEmpty: {

  //   marginTop: theme.spacing(2),
  //   // marginTop: '10px'
  // },
  card: {
    padding: "20px", // Add padding to the card
    margin: "20px", // Add margin around the card
  },
  cardActions: {
    justifyContent: "flex-end",
    marginTop: "20px",
  },
  submitButton: {
    backgroundColor: "blue",
    color: "white",
  },
  textField: {
    marginBottom: "20px",
  },
});

class StudentDetails extends Component {
  state = {
    state: "",
    district: "",
    gender: "",
    fathersName: "",
    mothersName: "",
    fathersPhoneNumber: "",
    fathersOccupation: "",
    mothersOccupation: "",
    category: "",
    religion: "",
    permanentAddress: "",
    correspondenceAddress: "",
    localGuardian: "",
    motherTongue: "",
    errors: [],
    
  };

  


  componentDidMount() {
    // pull the student data here
    GetData(
      `/${this.props.user.api_key}/${this.props.user.email}/getstudentdetails`
    ).then((resp) => {
      if (resp != false) {
        this.setState({
          ...resp,
          fathersName: resp.father_name,
          mothersName: resp.mother_name,
          fathersPhoneNumber: resp.father_phone,
          fathersOccupation: resp.father_profession,
          mothersOccupation: resp.mother_profession,
          category: resp.caste,
          permanentAddress: resp.permanent_address,
          correspondenceAddress: resp.present_address,
          localGuardian: resp.local_g,
          motherTongue: resp.mother_tongue,
        });
      }
    });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleValidation = () => {
    const {
      state,
      district,
      gender,
      fathersName,
      mothersName,
      fathersPhoneNumber,
      fathersOccupation,
      mothersOccupation,
      category,
      religion,
      permanentAddress,
      correspondenceAddress,
      localGuardian,
      motherTongue,
    } = this.state;

    let isValid = true;
    const errors = {};
    if (!state) {
      isValid = false;
      errors.state = "State is required";
    }

    if (!district.trim()) {
      isValid = false;
      errors.district = "District is required";
    }

    if (!gender.trim()) {
      isValid = false;
      errors.gender = "Gender is required";
    }

    if (!fathersName.trim()) {
      isValid = false;
      errors.fathersName = "Father's Name is required";
    }

    if (!mothersName.trim()) {
      isValid = false;
      errors.mothersName = "Mother's Name is required";
    }

    if (!fathersPhoneNumber.trim()) {
      isValid = false;
      errors.fathersPhoneNumber = "Father's Phone Number is required";
    }

    if (!fathersOccupation.trim()) {
      isValid = false;
      errors.fathersOccupation = "Father's Occupation is required";
    }

    if (!mothersOccupation.trim()) {
      isValid = false;
      errors.mothersOccupation = "Mother's Occupation is required";
    }

    if (!category) {
      isValid = false;
      errors.category = "Category is required";
    }

    if (!religion.trim()) {
      isValid = false;
      errors.religion = "Religion is required";
    }

    if (!permanentAddress.trim()) {
      isValid = false;
      errors.permanentAddress = "Permanent Address is required";
    }

    if (!correspondenceAddress.trim()) {
      isValid = false;
      errors.correspondenceAddress = "Correspondence Address is required";
    }

    if (!localGuardian.trim()) {
      isValid = false;
      errors.localGuardian = "Local Guardian is required";
    }

    if (!motherTongue.trim()) {
      isValid = false;
      errors.motherTongue = "Mother Tongue is required";
    }

    this.setState({ errors });
    return Object.keys(errors).length === 0;
  };

  onSubmit = (e) => {
    e.preventDefault();
    console.log(this.handleValidation());

    if (this.handleValidation()) {
      PostData(
        `/${this.props.user.api_key}/${this.props.user.email}/updatestudentowndetails`,
        { ...this.state }
      ).then((resp) => {
        message.success(resp);
      });
    } else {
    }
  };

  render() {
    const { classes } = this.props;
    const { errors, state, district} = this.state;

    return (
      <div>
        <form onSubmit={this.onSubmit}>
          <Card className={classes.card}>
            <CardHeader
              title={
                <div>
                  <Typography variant="h6">Student Details</Typography>
                  <Typography variant="caption">
                    Please provide the following details
                  </Typography>
                </div>
              }
            />
            <Divider />
            {this.state.error !== "" && (
              <Typography>
                {this.state.error}
                <br />
                <br />
              </Typography>
            )}
            <Grid container spacing={2}>
              <Grid item xs={12} md={3} lg={3} sm={12}>
                {/* <TextField
                  variant="outlined"
                  className={classes.textField}
                  fullWidth
                  required
                  label="State"
                  name="state"
                  value={this.state.state}
                  onChange={this.onChange}
                  error={Boolean(errors.state)}
                  helperText={errors.state}
                /> */}
                <StateSelect
                states={States} 
                onStateChange={(selectedState) => this.setState({ state: selectedState })}
                selectedState={this.state.state}
              
                />
              </Grid>
              <Grid item xs={12} md={3} lg={3} sm={12}>
                {/* <TextField
                  variant="outlined"
                  className={classes.textField}
                  fullWidth
                  required
                  label="District"
                  name="district"
                  value={this.state.district}
                  onChange={this.onChange}
                  error={Boolean(errors.district)}
                  helperText={errors.district}
                /> */}
                 <DistrictSelect
                  states={States}
                  selectedState={state}
                  onDistrictChange={(selectedDistrict) => this.setState({ district: selectedDistrict })}
                  selectedDistrict={district}
                  />
              </Grid>
              <Grid item xs={12} md={3} lg={3} sm={12}>
                <FormControl className={classes.FormControl} fullWidth>
                  <InputLabel id="category-label">Gender</InputLabel>
                  <Select
                    variant="outlined"
                    labelId="category-label"
                    id="gender"
                    name="gender"
                    value={this.state.gender}
                    required
                    onChange={this.onChange}
                    error={Boolean(errors.gender)}
                    helperText={errors.gender}
                  >
                    <MenuItem value={"MALE"}>MALE</MenuItem>
                    <MenuItem value={"FEMALE"}>FEMALE</MenuItem>
                    <MenuItem value={"OTHERS"}>OTHERS</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={3} lg={3} sm={12}>
                <TextField
                  variant="outlined"
                  className={classes.textField}
                  fullWidth
                  required
                  label="Father's Name"
                  name="fathersName"
                  value={this.state.fathersName}
                  onChange={this.onChange}
                  error={Boolean(errors.fathersName)}
                  helperText={errors.fathersName}
                />
              </Grid>
              <Grid item xs={12} md={3} lg={3} sm={12}>
                <TextField
                  variant="outlined"
                  className={classes.textField}
                  fullWidth
                  required
                  label="Mother's Name"
                  name="mothersName"
                  value={this.state.mothersName}
                  onChange={this.onChange}
                  error={Boolean(errors.mothersName)}
                  helperText={errors.mothersName}
                />
              </Grid>
              <Grid item xs={12} md={3} lg={3} sm={12}>
                <TextField
                  variant="outlined"
                  className={classes.textField}
                  fullWidth
                  required
                  label="Father's Phone Number"
                  name="fathersPhoneNumber"
                  value={this.state.fathersPhoneNumber}
                  onChange={this.onChange}
                  error={Boolean(errors.fathersPhoneNumber)}
                  helperText={errors.fathersPhoneNumber}
                />
              </Grid>
              <Grid item xs={12} md={3} lg={3} sm={12}>
                <TextField
                  variant="outlined"
                  className={classes.textField}
                  fullWidth
                  required
                  label="Father's Occupation"
                  name="fathersOccupation"
                  value={this.state.fathersOccupation}
                  onChange={this.onChange}
                  error={Boolean(errors.fathersOccupation)}
                  helperText={errors.fathersOccupation}
                />
              </Grid>
              <Grid item xs={12} md={3} lg={3} sm={12}>
                <TextField
                  variant="outlined"
                  className={classes.textField}
                  fullWidth
                  required
                  label="Mother's Occupation"
                  name="mothersOccupation"
                  value={this.state.mothersOccupation}
                  onChange={this.onChange}
                  error={Boolean(errors.mothersOccupation)}
                  helperText={errors.mothersOccupation}
                />
              </Grid>
              <Grid item xs={12} md={3} lg={3} sm={12}>
                <FormControl className={classes.FormControl} fullWidth>
                  <InputLabel id="category-label">Category/Caste</InputLabel>
                  <Select
                    variant="outlined"
                    labelId="category-label"
                    id="category"
                    name="category"
                    value={this.state.category}
                    required
                    onChange={this.onChange}
                    error={Boolean(errors.category)}
                    helperText={errors.category}
                  >
                    <MenuItem value={"GENERAL"}>GENERAL</MenuItem>
                    <MenuItem value={"SC"}>SC</MenuItem>
                    <MenuItem value={"ST(P)"}>ST(P)</MenuItem>
                    <MenuItem value={"OBC/MOBC"}>OBC/MOBC</MenuItem>
                    <MenuItem value={"ST(H)"}>ST(H)</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={3} lg={3} sm={12}>
                <FormControl className={classes.FormControl} fullWidth>
                  <InputLabel id="category-label">Religion</InputLabel>
                  <Select
                    variant="outlined"
                    labelId="category-label"
                    id="religion"
                    name="religion"
                    value={this.state.religion}
                    required
                    onChange={this.onChange}
                    error={Boolean(errors.religion)}
                    helperText={errors.religion}
                  >
                    <MenuItem value={"GENERAL"}>HINDUISM</MenuItem>
                    <MenuItem value={"SC"}>ISLAM</MenuItem>
                    <MenuItem value={"ST(P)"}>SIKHISM</MenuItem>
                    <MenuItem value={"OBC/MOBC"}>CHRISTIANITY</MenuItem>
                    <MenuItem value={"ST(H)"}>JAINISM</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              {/* <Grid item xs={12} md={3} lg={3} sm={12}>
                <TextField
                  variant="outlined"
                  className={classes.textField}
                  fullWidth
                  required
                  label="Religion"
                  name="religion"
                  value={this.state.religion}
                  onChange={this.onChange}
                />
              </Grid> */}
              <Grid item xs={12} md={3} lg={3} sm={12}>
                <TextField
                  variant="outlined"
                  className={classes.textField}
                  fullWidth
                  required
                  multiline
                  rows={4}
                  label="Permanent Address"
                  name="permanentAddress"
                  value={this.state.permanentAddress}
                  onChange={this.onChange}
                  error={Boolean(errors.permanentAddress)}
                  helperText={errors.permanentAddress}
                />
              </Grid>
              <Grid item xs={12} md={3} lg={3} sm={12}>
                <TextField
                  variant="outlined"
                  className={classes.textField}
                  fullWidth
                  required
                  multiline
                  rows={4}
                  label="Correspondence Address"
                  name="correspondenceAddress"
                  value={this.state.correspondenceAddress}
                  onChange={this.onChange}
                  error={Boolean(errors.correspondenceAddress)}
                  helperText={errors.correspondenceAddress}
                />
              </Grid>
              <Grid item xs={12} md={3} lg={3} sm={12}>
                <TextField
                  variant="outlined"
                  className={classes.textField}
                  fullWidth
                  required
                  label="Local Guardian"
                  name="localGuardian"
                  value={this.state.localGuardian}
                  onChange={this.onChange}
                  error={Boolean(errors.localGuardian)}
                  helperText={errors.localGuardian}
                />
              </Grid>
              <Grid item xs={12} md={3} lg={3} sm={12}>
                <TextField
                  variant="outlined"
                  className={classes.textField}
                  fullWidth
                  required
                  label="Mother Tongue"
                  name="motherTongue"
                  value={this.state.motherTongue}
                  onChange={this.onChange}
                  error={Boolean(errors.motherTongue)}
                  helperText={errors.motherTongue}
                />
              </Grid>
            </Grid>
            {/* ... Other form fields remain the same */}
            <CardActions className={classes.cardActions}>
              <Button
                variant="contained"
                type="submit"
                className={classes.submitButton}
              >
                Submit
              </Button>
            </CardActions>
          </Card>
        </form>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(StudentDetails);
