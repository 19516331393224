import React, { Component } from 'react';
import { withStyles, Grid, Card, CardHeader, Typography, TextField, Button, CardActions, Divider, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import FileUpload from '../../../FileUpload/FileUpload';


const styles = (theme) => ({
    formControl: {
        // margin: theme.spacing(2, 0),
        margin: '10px',
        minWidth: 120,
      },
      // selectEmpty: {

      //   marginTop: theme.spacing(2),
      //   // marginTop: '10px'
      // },
      card: {
        padding: '20px', // Add padding to the card
        margin: '20px', // Add margin around the card
      },
      cardActions: {
        justifyContent: 'flex-end',
        marginTop: '20px',
    },
    submitButton: {
        backgroundColor: 'blue',
        color: 'white'
    },
    textField: {
       marginBottom: '20px',
    },
});

class CoCurricularActivities extends Component {
    state = {
        coActivityName: '',
        coActivityTitle: '',
        coStartDate: '',
        coEndDate: '',
        coDescription: '',
        errors: {},
      };

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleValidation = () => {
    const { coActivityName, coActivityTitle, coStartDate, coEndDate, coDescription, coPhoto} = this.state;
    const errors = {};
    if(!coActivityName.trim()){
      errors.coActivityName = 'Activity name is required';
    }

    if(!coActivityTitle.trim()){
      errors.coActivityTitle = 'Activity title is required';
    }

    if(!coStartDate.trim()){
      errors.coStartDate = "Starting Date is required";
    }

    if (!coEndDate.trim()) {
      errors.coEndDate = 'Ending Date is required';
    }

    if (!coDescription.trim()) {
      errors.coDescription = 'Description is required';
    }

    this.setState({ errors });
    return Object.keys(errors).length === 0;
  };

  onSubmit = (e) => {
    e.preventDefault();

    let errors = this.state.errors;

    if (this.handleValidation()) {
    } else {
    }
  };

  render() {
    const { classes } = this.props;
    const { errors } = this.state;

    return (
      <div>
        <form onSubmit={this.onSubmit}>
          <Card className={classes.card}>
            <CardHeader
              title={
                <div>
                  <Typography variant="h6">Co-curricular activities Details</Typography>
                </div>
              }
            />
            <Divider />
            {/* {this.state.error !== '' && <Typography>{this.state.error}<br /><br /></Typography>} */}
            <Grid container spacing={24}>
              <Grid item xs={12} md={3} lg={3} sm={12}>
                <FormControl className={classes.FormControl}
                 fullWidth
                 >
                  <InputLabel id="category-label">Activity Name</InputLabel>
                  <Select
                    variant="outlined"
                    labelId="category-label"
                    id="coActivityName"
                    name="coActivityName"
                    value={this.state.coActivityName}
                    required
                    onChange={this.onChange}
                    error={Boolean(errors.coActivityName)}
                    helperText={errors.coActivityName}
                  >
                    <MenuItem value={'Debate & Quiz competition'}>Debate & Quiz competition(winner)</MenuItem>
                    <MenuItem value={'Singing/Dancing competition'}>Singing/Dancing competition(winner)</MenuItem>
                    <MenuItem value={'Sports'}>Sports(winner)</MenuItem>
                    <MenuItem value={'Others'}>Others</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={3} lg={3} sm={12}>
                <TextField
                  variant="outlined"
                  className={classes.textField}
                  fullWidth
                  required
                  label="Activity Title"
                  name="coActivityTitle"
                  value={this.state.coActivityTitle}
                  onChange={this.onChange}
                  error={Boolean(errors.coActivityTitle)}
                  helperText={errors.coActivityTitle}
                />
              </Grid>
              <Grid item xs={12} md={3} lg={3} sm={12}>
                <TextField
                  variant="outlined"
                  className={classes.textField}
                  fullWidth
                  required
                  label="Starting Date"
                  name="coStartDate"
                  type="date"
                  value={this.state.coStartDate}
                  onChange={this.onChange}
                  error={Boolean(errors.coStartDate)}
                  helperText={errors.coStartDate}
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </Grid>
              <Grid item xs={12} md={3} lg={3} sm={12}>
                <TextField
                  variant="outlined"
                  className={classes.textField}
                  fullWidth
                  required
                  label="Ending Date"
                  name="coEndDate"
                  type="date"
                  value={this.state.coEndDate}
                  onChange={this.onChange}
                  error={Boolean(errors.coEndDate)}
                  helperText={errors.coEndDate}
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </Grid>
              <Grid item xs={12} md={3} lg={3} sm={12}>
                <TextField
                  variant="outlined"
                  className={classes.textField}
                  fullWidth
                  required
                  label="Description"
                  name="coDescription"
                  multiline
                  rows={4}
                  value={this.state.coDescription}
                  onChange={this.onChange}
                  error={Boolean(errors.coDescription)}
                  helperText={errors.coDescription}
                />
              </Grid>
              <Grid item xs={12} md={3} lg={3} sm={12}>
               <FileUpload
               type="photo"
               apikey=""
               setDp={(dp) => this.setState({ photo: dp })} 
               dp={this.state.photo}
                  />
               </Grid>
            </Grid>
            <CardActions className={classes.cardActions}>
              <Button variant="contained" type="submit" className={classes.submitButton}>
                Submit
              </Button>
            </CardActions>
          </Card>
        </form>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(CoCurricularActivities);