import React, { Component } from 'react'
import { withStyles, Button, Typography, Grid, Divider, Card, CardHeader, CardContent } from '@material-ui/core';
import { GetData } from '../../../api/service';
import ReactToPrint from 'react-to-print';
import './formprint.css'
import Barcode from 'react-barcode'
import Loading from '../Forms/Loading';
import PaymentResponse from './PaymentResponse';
import { APP_GOOD_NAME, getGoodSemester } from '../../../Helper/GlobalVariable';

const styles = theme => ({

})

class ViewForm extends Component {

    constructor(props) {
        super(props);

        this.state = {
            data: this.props.data[0],
            isLoaded: false,
            exam_list: [],

        }
        this.buttonRef = React.createRef();
        

    }


    

    componentDidMount() {

        
        this.setState({
            isLoaded: true
        })

    }


    render() {
        let { classes } = this.props;
        return (
            <div>

                <br />

                {this.state.isLoaded ?
                    <React.Fragment>
                    <div align="center">
                    {this.state.isLoaded ? (<Card raised>
                    <CardHeader
                        title={`Print Form`}
                    />
                    <CardContent>

                        <ReactToPrint
                            trigger={() =>
                                <Button
                                    variant="outlined"
                                    color="primary"

                                >
                                    Print
                        </Button>
                            }
                            ref={el => this.buttonRef = el}
                            content={() => this.componentRef}
                            pageStyle={{ margin: '10px' }}
                            copyStyles={true}
                        />


                        <br />
                        <br />

                        <div ref={el => (this.componentRef = el)}>
                            <ExaminationForm
                                
                                {...this.state.data}
                            />

                            <div style={{ pageBreakAfter: 'always' }}></div>

                            </div>
                        
                    </CardContent>
                </Card>) : null}
                    </div>

                    <br />

                   
                    <br />
{/* 
                  {this.state.isLoaded && <div align="center">
                       
                        <PaymentResponse
                            ref={el => (this.componentRef = el)}
                            data={this.state.data}
                        />
                    </div>} */}

                 </React.Fragment>
                 : <Loading />}


            </div>
        )
    }
}

export default withStyles(styles)(ViewForm);

class ExaminationForm extends Component {
    showSubjects = (subjects) => {
        let subs =
            Array.isArray(subjects) && subjects.map((el, index) =>
                `${el.paper_name} :: ${el.paper_code} (${el.sub_name} :: ${el.sub_code})`
            )
        return subs.join(',');
    }

    render() {
        let {classes} = this.props;
        return (
            <div>
                <style type="text/css">
                    {"@media print{@page {size: landscape, max-height:100%; max-width:100%}}"}
                </style>
                <table width="100%">
                    <tbody>
                        <tr>
                            <td width="20%">
                                <img
                                    alt="logo"
                                    src={require('./../../../assets/logo.jpg')}
                                    style={{ width: '50%' }}
                                />
                            </td>

                            <td width="60%" align="center">
                                <Typography
                                    variant="h5"
                                    style={{ fontWeight: 700 }}
                                >
                                    {APP_GOOD_NAME}
                                </Typography>
                                <Typography
                                    variant="body2"
                                >
                                    Dr. B.Baruah Road, Ulubari
                                </Typography>
                                <Typography
                                    variant="body2"
                                >
                                    Guwahati, Assam,India PIN : 781007
                                </Typography>

                                <Typography
                                    variant="body1"
                                >
                                   <b> Examination Form ({this.props.exam_type} EXAMINATION) - {this.props.session_name}</b>
                                </Typography>
                            </td>

                            <td width="20%" align="center">
                                <Typography
                                    variant="subtitle2"
                                >


                                    <Barcode
                                        value={this.props.form_id}
                                        height="50"
                                        displayValue={false}
                                    /><br />
                                    Form No. : <b>{this.props.form_id}</b>
                                </Typography>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <Divider />
                <br />


                <table width="100%" className="table">
                    <tbody>
                        <tr>
                            <td width="10%" >
                                <Typography>
                                    Applicant Name
                                </Typography>
                            </td>
                            <td width="15%">
                                <Typography
                                    variant="body2"
                                    style={{ fontSize: '16px' }}
                                >
                                    {this.props.name}
                                </Typography>
                            </td>
                            <td width="10%" >
                                <Typography>
                                    Phone
                                </Typography>
                            </td>
                            <td width="15%">
                                <Typography
                                    variant="body2"
                                    style={{ fontSize: '16px' }}
                                >
                                    {this.props.phone}
                                </Typography>
                            </td>

                            <td width="10%">
                                <Typography>
                                    Email
                                </Typography>
                            </td>
                            <td width="15%">
                                <Typography
                                    variant="body2"
                                    style={{ fontSize: '16px' }}
                                >
                                    {this.props.email}
                                </Typography>
                            </td>
                        </tr>

                        <tr>
                            <td width="10%" >
                                <Typography>
                                    Course
                                </Typography>
                            </td>
                            <td width="15%">
                                <Typography
                                    variant="body2"
                                    style={{ fontSize: '16px' }}
                                >
                                    {this.props.course} {this.props.dept_code != ''? (this.props.dept_code) : ''}
                                </Typography>
                            </td>
                           

                            <td width="20%">
                                <Typography>
                                    University Roll & No
                                </Typography>
                            </td>
                            <td width="30%">
                                <Typography
                                    variant="body2"
                                    style={{ fontSize: '16px' }}
                                >
                                    {this.props.university_roll_no}
                                </Typography>
                            </td>
                       
                            <td width="10%" >
                                <Typography>
                                    Exam Appearing
                                </Typography>
                            </td>
                            <td width="20%">
                                <Typography
                                    variant="body2"
                                    style={{ fontSize: '16px' }}
                                >
                                    {getGoodSemester(this.props.exam_appearing)}
                                </Typography>
                            </td>
                           
                            
                        </tr>


                        <tr>
                            <td width="10%" >
                                <Typography>
                                    Type of Course
                                </Typography>
                            </td>
                            <td width="15%" colSpan={2}>
                                <Typography
                                    variant="body2"
                                    style={{ fontSize: '16px' }}
                                >
                                    {this.props.course_main_type} {this.props.type_of_major == "1M2m" && "- 1 Major 2 Minor"} {this.props.type_of_major == "3m" && "- 3 Minor"}
                                </Typography>
                            </td>
                           

                          
                           
                            
                        </tr>


                        <tr>

                            <td width="10%" >
                                <Typography>
                                    Papers Selected
                                </Typography>
                            </td>

                            <td colspan="5" width="90%" >
                                <Typography>
                                    <ol>
                                        {Array.isArray(this.props.paper_details) && this.props.paper_details.map((el, index) =>
                                        <li>
                                            {el.paper_name} :: {el.paper_code} :: {el.sub_name}
                                        </li>
                                            )}
                                    </ol>
                                   
                                </Typography>
                            </td>

                        </tr>

                        
                    </tbody>
                </table>

            </div>
        )
    }
}


