import React, { Component } from 'react'
import { GetData, PostData } from '../../api/service';


import { orange } from '@material-ui/core/colors';
import { Typography, Grid, Card, CardContent, CardActions, Button } from '@material-ui/core';
import AlertDiv from './AlertDiv';
//import FileUpload from '../ProfileComponent/FileUpload';
import ActivityTable from './ActivityTable';
import FileUpload from '../Registration/Forms/FileUpload';

export default class AcademicActivityLanding extends Component {
    constructor(props) {
        super(props);

        this.state = {
            activity_type: 'academic',
            activity_name: '',
            activity_title:'',
            description:'',
            upload_info:[],
            event_date:'',
            end_date:'',
            activities: [],
            my_activities:[],
            timeIsNotRight: false,
            isLoaded:false
        }

    }

    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    onChangePhoto = (name, value) => {
        this.setState({
            [name]: value
        })
    }


    __fetchData = () => {
        GetData(`/academic/getactivities`)
            .then((resp) => {
                //console.log(resp)
               this.setState({
                   activities:resp
               })
            })

            GetData(`/${this.props.user.email}/academic/getmyactivity`)
            .then((resp) => {
                //console.log(resp)
               this.setState({
                   my_activities:resp,
                   isLoaded:true
               })
            })
    }

    componentDidMount() {
        this.__fetchData();
        //console.log(this.props.user.email)

        var d = new Date();
        var n = d.getHours();
       

    //  if ((n >= 17 && n <= 23) || n >= 1 && n <= 8) {
    //         this.setState({
    //             timeIsNotRight: false
    //         })
    //     } else {
    //         this.setState({
    //             timeIsNotRight: true
    //         })
    //     } 
    }

    onSubmit = (e) => {
        e.preventDefault();

        let d ={
           ...this.state,username:this.props.user.email
        }

        PostData(`/${this.props.apikey}/addactivity`, d)
        .then((resp) => {
            //console.log(resp)
            if (resp.status != false) {
               alert('Successfully submitted');
               window.location.reload();
            } else {
                alert('Failed to submit');
            }
        })
    }


    render() {
        return (
            <div>

                {this.state.timeIsNotRight ? (
                    <div>
                        <TimeIsNotRight />
                    </div>
                ) : (<div>
                    <form onSubmit={this.onSubmit}>
                        <Card>
                            <CardContent>
                                <Typography
                                    variant="body1"
                                >
                                   Academic Activity Submission Form
                                </Typography>
                            </CardContent>
                            <CardContent>
                                <Grid container spacing={2}>
                               {this.state.activities.length>0 && <Grid item xs={12} sm={12} lg={4} md={4}>
                                        <Typography>Activity Name</Typography>
                                        <select
                                         className="form-control"
                                         type="text"
                                         required
                                         name="activity_name"
                                         value={this.state.activity_name}
                                         onChange={this.onChange}
                                         >
                                             <option value="">Select Type</option>
                                             {this.state.activities.map((el,index)=>
                                             <option value={el} key={index}>{el}</option>
                                             )}
                                             </select>
                                        </Grid>}

                                        <Grid item xs={12} sm={12} lg={4} md={4}>
                                        <Typography>Activity Title</Typography>
                                        <input
                                            className="form-control"
                                            type="text"
                                            required
                                            name="activity_title"
                                            value={this.state.activity_title}
                                            onChange={this.onChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} lg={4} md={4}>
                                        <Typography>Start Date</Typography>
                                        <input
                                            className="form-control"
                                            type="date"
                                            
                                            name="event_date"
                                            value={this.state.event_date}
                                            onChange={this.onChange}
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={12} lg={4} md={4}>
                                        <Typography>End Date</Typography>
                                        <input
                                            className="form-control"
                                            type="date"
                                            
                                            name="end_date"
                                            value={this.state.end_date}
                                            onChange={this.onChange}
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={12} lg={4} md={4}>
                                        <Typography>Description</Typography>
                                                <textarea 
                                                 className="form-control"
                                                 rows="4"
                                                 required
                                                 name="description"
                                                 value={this.state.description}
                                                 onChange={this.onChange}
                                                ></textarea>
                                        </Grid>

                                       
                                <Grid item xs={12} sm={12} lg={6} md={6}>
                                   

                                    <FileUpload
                                        title={
                                            <Typography
                                                variant="subtitle2"

                                            >
                                                Upload Photo (size below 500kb)
                                            </Typography>
                                        }
                                        type={`ACTIVITY_PHOTO`}
                                        apikey={this.props.user.api_key}
                                        onChange={this.onChangePhoto.bind(this, 'upload_info')}
                                        data={this.state.upload_info}

                                    />

                                    <AlertDiv
                                        
                                    >

                                        <ul>
                                           
                                            <li><Typography>Dimensions 200 x 230 pixels (preferred)</Typography></li>
                                            <li><Typography>Ensure that the size of the scanned image is not more than 500KB</Typography></li>
                                            <li><Typography>Only .jpg or .png extensions are allowed</Typography></li>
                                            <li><Typography>After Choosing the file, press "Upload" button.</Typography></li>
                                            <li><Typography>After successful upload, the file will appear in the white box</Typography></li>
                                        </ul>

                                    </AlertDiv>
                                </Grid>
                                    
                                    <Grid item xs={12} sm={12} lg={2} md={2} style={{ marginTop: '20px' }}>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            size="small"
                                            type="submit"
                                        >
                                            Submit
                                        </Button>
                                    </Grid>
                                </Grid>
                            </CardContent>


                        </Card>
                    </form>

                    <br /><br />
                   {this.state.isLoaded && <ActivityTable 
                    my_activities={this.state.my_activities} />
                    }


                </div>)}
            </div>
        )
    }
}


const TimeIsNotRight = (props) => {
    return (
        <div>
            <div style={{ border: `solid thin ${orange[400]}`, backgroundColor: orange[100], padding: '20px' }} align="center">
                <Typography
                    variant="overline"
                >
                    You can access this portal from 5 PM to 11 PM and from 1 AM to 8AM only.
                </Typography>
            </div>
        </div>
    )
}