import React, { Component } from "react";
import Navbar from "../Components/Navbar";
import StudentAcademicLanding from "../Components/Homepage/Studentpersonal/StudentAcademicLanding";

class AcademicDetailsContainer extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div>
        <StudentAcademicLanding history={this.props.history} {...this.props} />
      </div>
    );
  }
}

export default AcademicDetailsContainer;
