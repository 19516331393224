import React, { Component } from 'react';
import Notifications, { notify } from 'react-notify-toast';
import { Link } from 'react-router-dom';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import classnames from 'classnames';
import { withStyles } from '@material-ui/core/styles';

import FormControl from '@material-ui/core/FormControl';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Fade from '@material-ui/core/Fade';

import LinearProgress from '@material-ui/core/LinearProgress';
import { Typography, IconButton, CircularProgress } from '@material-ui/core';
import { PostData } from '../../api/service';

const styles = theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '70vh',
    justifyContent: 'center',
    alignItems: 'center',
  },
  loginDiv: {
    display: 'flex',
    flexDirection: 'column',
    width: '90%',
    padding: '20px'
  },
  content: {
    position: 'relative'
  },
  button: {
    backgroundColor: '#2196F3'
  },
  inputText: {
    padding: '15px',
    paddingLeft: '25px',
    border: 'solid thin lightgrey',

    backgroundColor: '#F9F9F9',
    marginBottom: '20px',
    outline: 'none'
  },
  loginText: {
    fontSize: 25,
    fontWeight: 200
  }
});

class NewLogin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      isLoading: false
    };
    this.handleChange = this.handleChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);

  }

  componentDidMount() {
    let data = this.props.history.location.state;
    if (data !== undefined) {
      this.setState({
        username: data.email,
        password: data.password
      })
    }
  }

  componentWillMount() {

  }
  onSubmit(e) {
    e.preventDefault();
    this.setState({
      isLoading: true
    })

    let d = {
      email: this.state.username,
      password: this.state.password
    }

    PostData(`/loginforexam/login`, d)
      .then((res) => {
        if (res.user.status !=0) {
          if (res.hasOwnProperty('token')) {
            //console.log(res)
            this.setToken(res.token);
            this.setUser(res);
            this.setApikey(res.user.apikey);
            if (res.role === 'STUDENT') {
              this.props.history.replace('/home')
            } 

          } else {
            throw "error";
          }
        } else {
          throw "notactive";
        }
      })
      .catch(err => {

        notify.show('Wrong Username or password', "error", 3000);
        this.setState({
          isLoading: false
        })


      });

  }

  setUser(user) {
    const val = JSON.stringify(user);
    localStorage.setItem('user', val);
  }
  setApikey(key) {
    const val = JSON.stringify(key);
    localStorage.setItem('apikey', val);
  }
  setToken(idToken) {
    // Saves user token to localStorage
    localStorage.setItem('id_token', idToken)
  }
  handleChange(e) {
    this.setState(
      {
        [e.target.name]: e.target.value
      }
    )
  }
  render() {
    const { classes } = this.props;
    return (
      <section className={classes.container} >
        <Notifications />

        <Card style={{ minHeight: '70vh' }}>
          <CardContent>



            <div >

              <section className={classes.loginDiv}>

                <div align="center">
                  <Typography
                    className={classes.loginText}
                    gutterBottom
                  >
                    Login
                </Typography>
                </div>

                <br />

                <div>
                  <form onSubmit={this.onSubmit}>

                    <FormControl fullWidth required>
                    <label>Registered Email Id / User Id *</label>
                      <input
                        required
                        className={classes.inputText}
                        name="username"
                        value={this.state.username}
                        onChange={this.handleChange}
                        placeholder="Enter your registered Email Id / User Id"
                      />

                    </FormControl>



                    <FormControl fullWidth required>
                        <label>Password *</label>
                      <input
                        required
                        className={classes.inputText}
                        type="password"
                        name="password"
                        value={this.state.password}
                        onChange={this.handleChange}
                        placeholder="Enter your password here"
                      />
                    </FormControl>

                    <br />

                    <div style={{ textAlign: 'right' }}>
                      <Link to="/forgotpassword">
                        <Typography
                          variant="caption"
                        >
                          Forgot password ? Click Here
                                    </Typography>
                      </Link>
                    </div>

                    <br />

                    <div align="center">
                      <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        size="large"
                        className={classes.button}
                      >
                        {this.state.isLoading ? <CircularProgress size={24} style={{ color: 'white' }} /> : 'Login'}
                      </Button>


                    </div>
                  </form>
                </div>
                <br />
                <br />







              </section>

            </div>

          </CardContent>
        </Card>
      </section>
    );
  }
}

export default withStyles(styles)(NewLogin);

export const IconLogin = (props) => {
  let { classes } = props;
  return (
    <div className={classes.iconlogin}>

    </div>
  )
}