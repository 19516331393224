import React, { Component } from 'react'
import { withStyles, Stepper, Step, StepButton } from '@material-ui/core';
import FormOne from './FormOne';
import { PostData, GetData, PutData } from '../../../api/service';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import PaymentGo from './PaymentGo';
import Notifications, { notify } from 'react-notify-toast';
import ViewForm from '../View/ViewForm';
import MyExamLanding from '../View/MyExamLanding';

const styles = theme => ({
    type: {
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        }
    }
})

const steps = [
    {
        label: 'Form Details'
    },
    {
        label: 'Document Upload'
    },
    {
        label: 'Print Form'
    }
]

class FormsLanding extends Component {
    state = {
        step: 0,
        email: this.props.user.email,
        data: [],
        completed: [],
        isLoaded: false
    }

    componentDidMount() {
        //console.log()
        this.getInitialData()
    }


    getInitialData = () => {
        GetData(`/${this.props.user.email}/examform/getexamdata`)
            .then((resp) => {
                //console.log(resp)
                if (Array.isArray(resp) && resp.length > 0) {
                   
                    this.setState({
                        ...resp[0],
                        data: resp,
                        isLoaded: true
                    })
                } else {
                    this.setState({
                        isLoaded: true,
                        step: 0
                    })
                }

            })
    }

    onSetData = (data) => {
        //console.log(data)

        PostData(`/${data.email}/examformentry/${data.step}`, data)
        .then(resp=>{
            if(resp.status===true){
               this.getInitialData();
                confirmAlert({
                    title: 'Successful',
                    message: 'Successfully submitted.',
                    buttons: [
                        {
                            label: 'Okay',
                            
                        }
                    ]
                });
            } else {
                confirmAlert({
                    title: 'Failed!!',
                    message: resp.message,
                    buttons: [
                        {
                            label: 'Reload',
                            //onClick: () => window.location.reload()
                        }
                    ]
                });
            }
        
        })
 
        
    }


    proceedToBack = () => {
        //get the next step
        let next_step = 0;
        if (this.state.step === 0) {
            next_step = 0;
        } else if (this.state.step === 1) {
            next_step = 0;
        } else if (this.state.step === 2) {
            next_step = 1;
        } else if (this.state.step === 3) {
            next_step = 2;
        }

        this.setState({
            step: next_step
        })
    }



render() {
    let { classes } = this.props;
    let i = [];

    if (this.state.step === 0) {
        i = <FormOne
            history={this.props.history}
            user={this.props.user}
            setData={this.onSetData.bind(this)}
            email={this.state.email}
            data={this.state.data}
            next={this.proceedToNext}
            back={this.proceedToBack}
        />
    }

    else if (this.state.step === 1) {
        i = <PaymentGo
            user={this.props.user}
            email={this.state.email}
            history={this.props.history}
            setData={this.onSetData.bind(this)}
            data={this.state.data}
            next={this.proceedToNext}
            back={this.proceedToBack}
        />
    }

    else if (this.state.step === 2) {
        i = <ViewForm
            user={this.props.user}
            email={this.state.email}
            history={this.props.history}
            data={this.state.data}
            next={this.proceedToNext}
            back={this.proceedToBack}
        />
    }
    

    return (
        <div>

            <Notifications />

            <Stepper nonLinear activeStep={this.state.step} className={classes.type}>
                {steps.map((el, index) =>

                    <Step key={index}>
                        <StepButton
                        /* onClick={() => this.gotToStep(index)} */

                        >
                            {el.label}
                        </StepButton>
                    </Step>

                )}
            </Stepper>

            <br />

            <br />
            {this.state.isLoaded  && this.state.data.length > 0 && <MyExamLanding
                    user={this.props.user}
                    data={this.state.data[0]}
                    history={this.props.history}
                />} 
            {this.state.isLoaded ? i : null}
        </div>
    )
}
}

export default withStyles(styles)(FormsLanding)