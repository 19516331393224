import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import {
  withStyles,
  Card,
  CardHeader,
  Typography,
  CardContent,
  CardActions,
  Button,
  Badge,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@material-ui/core";
import { blue, red, lightGreen } from "@material-ui/core/colors";
import AlertDiv from "../Registration/Forms/AlertDiv";

const styles = (theme) => ({
  cardHeaderDiv: {
    backgroundColor: blue[200],
    color: "white",
  },
  button: {
    backgroundColor: red[500],
    color: "white",
    border: `solid thin ${red[500]}`,
    "&:hover": {
      color: red[500],
      backgroundColor: "white",
    },
  },
});

class InstructionsDiv extends Component {
  state = {
    open: false,
    // open1: false,
  };

  onClick = () => {
    this.setState({
      open: !this.state.open,
    });
  };

  handleStudentDetailsClick = () => {
    this.props.history.push("/student-details");
  };

  handleAcademicDetailsClick = () => {
    this.props.history.push("/academic-details");
  };

  handleCoCurricularClick = () => {
    this.props.history.push("/cocurricular-activities");
  };

  render() {
    let { classes } = this.props;
    return (
      <div>
        <Card style={{ minHeight: "70vh" }}>
          <CardHeader
            className={classes.cardHeaderDiv}
            title={
              <Typography variant="h6" color="inherit">
                Applicant /Students' Sign Up
              </Typography>
            }
          />

          <CardContent>
            <Typography variant="body1">
              Read the guidelines mentioned in the bottom of the page before
              continue
              <br />
              <br />
              You need to register yourself by clicking on the 'New Applicant
              Registration' button. After that log in to fill-up the form.
              <br />
              <br /> It is absolutely necessary that you use your own phone
              number and email id while filling up this form. If you don't have
              any email id, please <b>create before you continue</b>.
              <br />
              <br />
              Users already registered can log on to the system by providing
              their Registered Email ID and Date Of Birth in the respective
              fields. 
              <br />
              <br />
              You can add Academic Details, Student Details and
              Activities after loggin in.
            </Typography>
          </CardContent>

          <CardActions>
            <Button
              variant="contained"
              onClick={this.onClick}
              size="small"
              color="primary"
            >
              New Applicant Registration
            </Button>

            {/* <Button
                                variant="contained"
                                onClick={this.handleStudentDetailsClick}
                                size="small"
                                color="primary"
                            >
                                Student Details Form 
                        </Button>

                        <Button
                                variant="contained"
                                onClick={this.handleAcademicDetailsClick}
                                size="small"
                                color="primary"
                            >
                                Academic Details 
                        </Button>

                        <Button
                                variant="contained"
                                onClick={this.handleCoCurricularClick}
                                size="small"
                                color="primary"
                            >
                                Co-Curricular Activities 
                        </Button> */}
          </CardActions>
        </Card>

        <DialogBox open={this.state.open} handleClose={this.onClick} />
      </div>
    );
  }
}

export default withStyles(styles)(withRouter(InstructionsDiv));

const DialogBox = (props) => {
  return (
    <div>
      <Dialog
        fullScreen={false}
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Important Notice"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please keep the following documents ready for online form
            submission:
          </DialogContentText>
          <ol>
            <li>
              <Typography>A valid active mobile number</Typography>
            </li>
            <li>
              <Typography>
                A valid email ID ( all future communications will be sent to the
                registered mobile number and email id)
              </Typography>
            </li>
            <li>
              <Typography>
                Scanned copy of G.U Examination Form (Size below 500KB and .jpg
                format)
              </Typography>
            </li>
            <li>
              <Typography>
                Scanned copy of G.U Exam Fees Receipt (Size below 500KB and .jpg
                format)
              </Typography>
            </li>
          </ol>
        </DialogContent>
        <DialogActions>
          <Link to="/registration">
            <Button onClick={props.handleClose} color="primary" autoFocus>
              Go to registration
            </Button>
          </Link>

          {/* <Link to="/student-details">
                <Button variant="contained" size="small" color="primary">
                  Student Details Form
                </Button>
          </Link> */}
        </DialogActions>
      </Dialog>
    </div>
  );
};
