import React, { Component } from 'react'
import {Link} from 'react-router-dom'
import { withStyles, Typography, Divider, Button, Card, CardHeader, CardContent } from '@material-ui/core';
import { GetData } from '../../../api/service';
import ReactToPrint from 'react-to-print';
import Loading from '../Forms/Loading';
import { APP_GOOD_NAME } from '../../../Helper/GlobalVariable';

const styles = theme => ({

})

class PaymentResponse extends Component {
    state = {
        data: this.props.data,
        isLoaded: false
    }

    componentDidMount() {

        this.setState({
            isLoaded:true
        })
       
    }


    render() {
        let { classes } = this.props;
        return (
            <div>
            {this.state.isLoaded ? (<Card raised>
                <CardHeader
                    title={`Print Receipt`}
                />
                <CardContent>

                    <ReactToPrint
                        trigger={() =>
                            <Button
                                variant="outlined"
                                color="primary"

                            >
                                Print
                    </Button>
                        }
                        ref={el => this.buttonRef = el}
                        content={() => this.componentRef}
                        pageStyle={{ margin: '10px' }}
                        copyStyles={true}
                    />


                    <br />
                    <br />

                    <div ref={el => (this.componentRef = el)}>
                        <Receipt
                            
                            {...this.state.data}
                        />

                        <div style={{ pageBreakAfter: 'always' }}></div>

                        </div>
                    
                </CardContent>
            </Card>) : null}
        </div>
        )
    }
}

export default withStyles(styles)(PaymentResponse);

class Receipt extends Component {

    render() {
        return (
            <div>
                <table width="100%">
                    <tbody>
                        <tr>
                            <td width="20%">
                                <img
                                    alt="logo"
                                    src={require('./../../../assets/logo-wide.png')}
                                    style={{ width: '50%' }}
                                />
                            </td>

                            <td width="60%" align="center">
                                <Typography
                                    variant="h5"
                                >
                                   {APP_GOOD_NAME}
                                </Typography>
                                <Typography
                                    variant="body2"
                                >
                                   Dr. B.Baruah Road, Ulubari
                                </Typography>
                                <Typography
                                    variant="body2"
                                >
                                    Guwahati, Assam,India PIN : 781007
                                </Typography>
                                <Typography
                                    variant="body1"
                                >
                                    Examination Receipt
                                </Typography>
                            </td>

                            <td width="20%" align="center">
                                <Typography
                                    variant="subtitle2"
                                >
                                    Form No : <b>{this.props.form_id}</b> <br />
                                    Receipt No : <b>EX{this.props.receipt_no}</b> <br />
                                    Date : <b>{this.props.time_stamp}</b>
                                </Typography>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <br />
                <br />

                <table className="table">

                    <tbody>
                        <tr>
                            <td width="30%">
                                <Typography
                                    variant="body2"
                                >
                                    Applicant Name
                                </Typography>
                            </td>

                            <td>
                                <Typography
                                    variant="body2"
                                >
                                    {this.props.name}
                                </Typography>
                            </td>
                        </tr>

                        <tr>
                            <td width="30%">
                                <Typography
                                    variant="body2"
                                >
                                    Exam Fees
                                </Typography>
                            </td>

                            <td>
                                <Typography
                                    variant="body2"
                                >
                                    {this.props.exam_fees}
                                </Typography>
                            </td>
                        </tr>

                        <tr>
                            <td width="30%">
                                <Typography
                                    variant="body2"
                                >
                                    Centre Fees
                                </Typography>
                            </td>

                            <td>
                                <Typography
                                    variant="body2"
                                >
                                    {this.props.centre_fees}
                                </Typography>
                            </td>
                        </tr>

                        <tr>
                            <td width="30%">
                                <Typography
                                    variant="body2"
                                >
                                    Practical Fees
                                </Typography>
                            </td>

                            <td>
                                <Typography
                                    variant="body2"
                                >
                                    {this.props.practical_fees}
                                </Typography>
                            </td>
                        </tr>

                        <tr>
                            <td width="30%">
                                <Typography
                                    variant="body2"
                                >
                                    Non Recognized Subject Fees
                                </Typography>
                            </td>

                            <td>
                                <Typography
                                    variant="body2"
                                >
                                    {this.props.subject_fees}
                                </Typography>
                            </td>
                        </tr>
                        <tr>
                            <td width="30%">
                                <Typography
                                    variant="body2"
                                >
                                    Marksheet Fees
                                </Typography>
                            </td>

                            <td>
                                <Typography
                                    variant="body2"
                                >
                                    {this.props.marksheet_fees}
                                </Typography>
                            </td>
                        </tr>

                        

                        <tr>
                            <td width="30%">
                                <Typography
                                    variant="body2"
                                >
                                    Total Fees
                                </Typography>
                            </td>

                            <td>
                                <Typography
                                    variant="body2"
                                >
                                    {this.props.total_fees}
                                </Typography>
                            </td>
                        </tr>

                        <tr>
                            <td width="30%">
                                <Typography
                                    variant="body2"
                                >
                                    Date of Payment
                                </Typography>
                            </td>

                            <td>
                                <Typography
                                    variant="body2"
                                >
                                    {this.props.time_stamp}
                                </Typography>
                            </td>
                        </tr>

                        <tr>
                            <td width="30%">
                                <Typography
                                    variant="body2"
                                >
                                    Payment Status
                                </Typography>
                            </td>

                            <td>
                                <Typography
                                    variant="body2"
                                >
                                {this.props.payment_status == '0' ? 'Not Paid' : 'Paid'}

                                </Typography>
                            </td>
                        </tr>
                    </tbody>

                </table>
            </div>
        )
    }

}