export const APP_NAME = "EXAMINATION_PORTAL";
export const APP_GOOD_NAME = "B. BOROOAH COLLEGE";
export const DEFAULT_COLOR = "#1F53CC";
export const DRAWER_WIDTH = 180;

export const BaseURL = process.env.NODE_ENV === 'production' ? 'https://backend.bborooahcollege.ac.in/public' : 'http://localhost/bbc-erp/api/public';
export const WebLoginUrl = process.env.NODE_ENV === 'production' ? 'https://backend.bborooahcollege.ac.in' : 'http://localhost:3001';

export const UploadPath = process.env.NODE_ENV === 'production' ? 'https://backend.bborooahcollege.ac.in/src/uploads' : 'http://localhost/bbc-erp/api/src/uploads';

export const ACCESS_CONTROL = [
    {
        text: 'ACCOUNTS',
        value: 'ACC'
    },
    {
        text: 'ADMISSION',
        value: 'ADM'
    },
    {
        text: 'FEES COLLECTION',
        value: 'FEE'
    },
    {
        text: 'REPORTS',
        value: 'REP'
    },
    {
        text: 'LEAVE-PRINCIPAL',
        value: 'LEAVEPRINCE'
    }
]


export const COURSE_TYPE_LIST = ["HONOURS","REGULAR"];
export const GENDER_LIST = ["MALE","FEMALE","OTHERS"];
export const CASTE_LIST = ["GENERAL","ST(P)","ST(H)","SC", "OBC"];
export const RELIGION_LIST = ["HINDUISM", "ISLAM", "CHRISTIANISM", "JAINISM", "SIKKHISM"];

export function getGoodSemester(semester){
    if(semester == '1'){
        return `1st Semester`;
    }else if(semester == '2'){
        return `2nd Semester`;
    }else if(semester == '3'){
        return `3rd Semester`;
    }else {
        return `${semester}th Semester`;
    }
}
