import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  selectfield: {
    marginBottom: '20px',
    width: '100%',
  },
}));

const StateSelect = ({ states, onStateChange, selectedState }) => {
   const classes = useStyles();

  const handleStateChange = (event) => {
    const selectedState = event.target.value;
    onStateChange(selectedState);
  };

  return (
    <div>
    <label htmlFor="state">Select State:</label>
    <select
      id="state"
      value={selectedState || ''}
      onChange={handleStateChange}
      className={classes.selectfield}
    >
      <option value="">Select a State</option>
      {states.map((state) => (
        <option key={state.id} value={state.name}>
          {state.name}
        </option>
      ))}
    </select>
  </div>
  );
};

export default StateSelect;
