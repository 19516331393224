import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  customSelect: {
    marginBottom: '20px',
    width: '100%',
  },
}));

const DistrictSelect = ({ states, selectedState, onDistrictChange, selectedDistrict }) => {
  const classes = useStyles();

  const handleDistrictChange = (event) => {
    const selectedDistrict = event.target.value;
    onDistrictChange(selectedDistrict);
  };

  const selectedStateData = states.find((state) => state.name === selectedState) || {};
  const districts = selectedStateData.districts || [];

  return (
    <div>
      <label htmlFor="district">Select District:</label>
      <select
        id="district"
        value={selectedDistrict || ''}
        onChange={handleDistrictChange}
        className={classes.customSelect}
      >
        <option value="">Select a District</option>
        {districts.map((district) => (
          <option key={district.name} value={district.name}>
            {district.name}
          </option>
        ))}
      </select>
    </div>
  );
};

export default DistrictSelect;
