import React, { Component } from "react";
import {
  withStyles,
  Card,
  CardHeader,
  Avatar,
  CardContent,
  Grid,
  Typography,
  Divider,
  CardActions,
  Button,
  Paper,
} from "@material-ui/core";
import { blue, orange } from "@material-ui/core/colors";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import FileUpload from "./FileUpload";
import { GetData, PostData } from "../../../api/service";
import AlertDiv from "./AlertDiv";
import Sel from "react-select";
import { Select } from "antd";
import { getGoodSemester } from "../../../Helper/GlobalVariable";

const { Option } = Select;

const styles = (theme) => ({
  buttonSubmit: {
    color: "white",
    backgroundColor: blue[400],
    "&:hover": {
      color: blue[400],
      backgroundColor: "white",
      border: `solid thin ${blue[400]}`,
    },
  },
  cardActions: {
    justifyContent: "flex-end",
  },
  redStrip: {
    width: "100%",
    height: 3,
    backgroundColor: "#17408C",
  },
});

class FormOne extends Component {
  state = {
    isLoaded: true,
    step: 0,
    role: "",
    form_id: "",
    name: this.props.user.name,
    email: this.props.user.email,
    phone: "",
    date_of_birth: "",
    receipt_no: "",
    course: "",
    course_main_type: "",
    exam_type: "",
    university_roll_no: "",
    college_roll_no: "",
    type_of_major: "",
    phone: "",
    exam_appearing: "",
    error: "",
    errors: [],
    gu_exam_form: [],
    gu_fees_receipt: [],
    courses: [],
    semesters: [],
    departments: [],
    sessions: [],
    papers: [],
    f_papers: [],
    dept_code: "",
    session_appearing: "",
    papers_selected: [],
    major_1: '',
    minor_1: '',
    minor_2: '',
    minor_3: '',
    mdc: '',
    vac: '',
    sec: '',
    aec: '',
    subjects: []
  };

  componentDidMount() {
    window.scrollTo(0, 0);

    GetData(`/getcourse`).then((courses) => {
      GetData(`/getdepartment`).then((departments) => {
        GetData(`/getsession`).then((sessions) => {
          GetData(`/getpapercode`).then((papers) => {
            let data = this.props.data;

            if (Array.isArray(data) && data.length > 0) {
              let semesters = [];
              let temp = courses.filter((el) => el.name == data[0].course);
              if (temp.length > 0) {
                for (let n = 1; n <= parseInt(temp[0]["no_of_term"]); n++) {
                  semesters.push(n);
                }
              }

              this.setState(
                {
                  courses,
                  departments,
                  sessions,
                  papers,
                  semesters,
                  ...data[0],
                },
                () => this.getSubjects()
              );
            } else {
              this.setState({
                courses,
                departments,
                sessions,
                papers,
              });
            }

            //console.log('step',this.state.step)
          });
        });
      });
    });


    GetData(`/getsubjectnep`).then((resp) => {
      
    this.setState({
      subjects: resp
    })
    })
  }

  getSubjects = () => {
    if (
      this.state.course !== "" &&
      this.state.course !== null &&
      this.state.exam_appearing !== "" &&
      this.state.exam_appearing !== null
    ) {
      if (
        this.state.course === "BA" ||
        this.state.course === "BSC" ||
        this.state.course === "BBA" ||
        this.state.course === "BSCIT" ||
        this.state.course === "PG"
      ) {

        if(this.state.course_main_type == "NEP"){
          let f_papers = this.state.papers.filter(
            (el) =>
              el.course === this.state.course &&
              el.semester == this.state.exam_appearing && el.is_nep == "YES"
          );
          //console.log("🚀 ~ file: FormOne.js:155 ~ FormOne ~ papers:", f_papers)
  
          this.setState({
            f_papers,
          });
        }else{
          let f_papers = this.state.papers.filter(
            (el) =>
              el.course === this.state.course &&
              el.semester == this.state.exam_appearing
          );
          //console.log("🚀 ~ file: FormOne.js:155 ~ FormOne ~ papers:", f_papers)
  
          this.setState({
            f_papers,
          });
        }

        
      }
    }
  };

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });

    if (e.target.name === "course") {
      let semesters = [];
      let temp = this.state.courses.filter((el) => el.name == e.target.value);
      if (temp.length > 0) {
        for (let n = 1; n <= parseInt(temp[0]["no_of_term"]); n++) {
          semesters.push(n);
        }
      }

      this.setState(
        {
          [e.target.name]: e.target.value,
          semesters,
          papers_selected: [],
          dept_code: "",
        },
        () => {
          this.getSubjects();
        }
      );
    }

    if (e.target.name === "exam_appearing") {
      this.setState(
        {
          [e.target.name]: e.target.value,
          papers_selected: [],
        },
        () => {
          this.getSubjects();
        }
      );
    }
  };

  onSubChange = (name, e) => {
    this.setState({
      [name]: e,
    });
  };

  handleValidation = () => {
    let {
      exam_appearing,
      university_roll_no,
      college_roll_no,
      course,
      errors,
      papers_selected,
      session_appearing,
      exam_type,
      course_main_type,
      type_of_major,
      major_1,
      minor_1,
      minor_2,
      minor_3,
      mdc,
      vac,
      sec,
      aec
    } = this.state;

    let isValid = true;

    // if (course !== 'HS') {
    //     if (university_roll_no === '' || university_roll_no === null) {
    //         isValid = false;
    //         errors['university_roll_no'] = 'Please fill up this field'
    //     } else {
    //         errors['university_roll_no'] = '';
    //     }
    // }

    if (exam_type === "" || exam_type === null) {
      isValid = false;
      errors["exam_type"] = "Please fill up this field";
    } else {
      errors["exam_type"] = "";
    }

    if (course === "" || course === null) {
      isValid = false;
      errors["course"] = "Please fill up this field";
    } else {
      errors["course"] = "";
    }

    if (
      session_appearing === "" ||
      session_appearing === null ||
      session_appearing == 0
    ) {
      isValid = false;
      errors["session_appearing"] = "Please fill up this field";
    } else {
      errors["session_appearing"] = "";
    }

    if (
      exam_appearing === "" ||
      (exam_appearing === null && this.state.exam_appearing != "0")
    ) {
      isValid = false;
      errors["exam_appearing"] = "Please fill up this field";
    } else {
      errors["exam_appearing"] = "";
    }

    if(course_main_type == "NEP"){
      if(type_of_major == "1M3m"){
        if (
          major_1 === "" ||
          (major_1 === null)
        ) {
          isValid = false;
          errors["major_1"] = "Please fill up this field";
        } else {
          errors["major_1"] = "";
        }

        if (
          minor_1 === "" ||
          (minor_1 === null)
        ) {
          isValid = false;
          errors["minor_1"] = "Please fill up this field";
        } else {
          errors["minor_1"] = "";
        }

        if (
          minor_2 === "" ||
          (minor_2 === null)
        ) {
          isValid = false;
          errors["minor_2"] = "Please fill up this field";
        } else {
          errors["minor_2"] = "";
        }
      }

      if(type_of_major == "3m"){
        if (
          minor_3 === "" ||
          (minor_3 === null)
        ) {
          isValid = false;
          errors["minor_3"] = "Please fill up this field";
        } else {
          errors["minor_3"] = "";
        }

        if (
          minor_1 === "" ||
          (minor_1 === null)
        ) {
          isValid = false;
          errors["minor_1"] = "Please fill up this field";
        } else {
          errors["minor_1"] = "";
        }

        if (
          minor_2 === "" ||
          (minor_2 === null)
        ) {
          isValid = false;
          errors["minor_2"] = "Please fill up this field";
        } else {
          errors["minor_2"] = "";
        }
      }

      if (
        mdc === "" ||
        (mdc === null)
      ) {
        isValid = false;
        errors["mdc"] = "Please fill up this field";
      } else {
        errors["mdc"] = "";
      }

      if (
        vac === "" ||
        (vac === null)
      ) {
        isValid = false;
        errors["vac"] = "Please fill up this field";
      } else {
        errors["vac"] = "";
      }

      if (
        sec === "" ||
        (sec === null)
      ) {
        isValid = false;
        errors["sec"] = "Please fill up this field";
      } else {
        errors["sec"] = "";
      }


      if (
        aec === "" ||
        (aec === null)
      ) {
        isValid = false;
        errors["aec"] = "Please fill up this field";
      } else {
        errors["aec"] = "";
      }
    }else{
      if (
        (course === "BA" ||
          course === "BSC" ||
          course === "BBA" ||
          course === "BSCIT" ||
          course === "PG") &&
        Array.isArray(papers_selected) &&
        papers_selected.length === 0
      ) {
        isValid = false;
        errors["papers"] = "Please fill up this field";
      } else {
        errors["papers"] = "";
      }
    }

    

    //console.log(isValid)

    this.setState({
      errors: errors,
      step: 0,
    });

    return isValid;
  };

  onSubmit = async (e) => {
    e.preventDefault();

    //console.log(this.state)
    if (this.handleValidation()) {
      confirmAlert({
        title: "Confirm to submit",
        message: "Are you sure to submit ?",
        buttons: [
          {
            label: "Yes",
            onClick: () => {
               // get the paper codes

              //  let paper_codes = [];
              //  // we need to adjust the paper_codes
              //  if(this.state.major_1){
              //    paper_codes.push(this.state.major_1)
              //  }

              //  if(this.state.minor_1){
              //    paper_codes.push(this.state.minor_1)
              //  }

              //  if(this.state.minor_2){
              //    paper_codes.push(this.state.minor_2)
              //  }

              //  if(this.state.minor_3){
              //    paper_codes.push(this.state.minor_3)
              //  }

              //  if(this.state.mdc){
              //    paper_codes.push(this.state.mdc)
              //  }

              //  if(this.state.vac){
              //    paper_codes.push(this.state.vac)
              //  }

              //  if(this.state.sec){
              //    paper_codes.push(this.state.sec)
              //  }

              //  if(this.state.aec){
              //    paper_codes.push(this.state.aec)
              //  }
               
               this.props.setData({
                ...this.state,
                step: 0,
              })
            }
             
          },
          {
            label: "No",
          },
        ],
      });
    }
  };

  render() {
    let { classes } = this.props;

    return (
      <div>
        <form onSubmit={this.onSubmit}>
          <Paper style={{ padding: "10px" }}>
            <AlertDiv back={orange[100]} border={orange[400]}>
              <Typography variant="body2">
                Please choose the papers carefully.
              </Typography>
              <ul>
                <li>
                  <Typography>
                    Choose only those papers for which you are going to appear.
                  </Typography>
                </li>
                <li>
                  <Typography>
                    Do not choose one subject more than once for the same
                    examination. 
                  </Typography>
                </li>
                {/* <li>
                                        <Typography>
                                            If you pay less amount than the total fees then your application might get rejected.
                                        </Typography>
                                    </li> */}
                <li>
                  <Typography>
                    No modification will be allowed after final submission.
                  </Typography>
                </li>
                <li>
                  <Typography>
                    If you have any confusion or any subject not found in the
                    list then call at office number.
                  </Typography>
                </li>
              </ul>
            </AlertDiv>
            <div className={classes.redStrip}></div>
            <h2>Application Form</h2>
            <h4>* Marked Fields are mandatory</h4>

            {this.state.error !== "" ? (
              <AlertDiv back={orange[50]} border={orange[400]}>
                <Typography>{this.state.error}</Typography>
              </AlertDiv>
            ) : null}

            <Grid container spacing={16}>
              <Grid item xs={12} sm={12} lg={3} md={3}>
                {this.state.errors["course_main_type"] !== "" ? (
                  <div>
                    <Typography variant="caption" style={{ color: "red" }}>
                      {this.state.errors["course_main_type"]}
                    </Typography>
                  </div>
                ) : null}
                <Typography variant="subtitle2">
                  Type of Course <span className="red-alert">*</span>
                </Typography>
                <select
                  className="form-control"
                  type="text"
                  name="course_main_type"
                  required
                  value={this.state.course_main_type}
                  onChange={this.onChange}
                >
                  <option value="">Select</option>
                  {["NEP", "CBCS"].map((el) => (
                    <option value={el}>{el}</option>
                  ))}
                </select>
              </Grid>

              {this.state.course_main_type == "NEP" && (
                <Grid item xs={12} sm={12} lg={3} md={3}>
                  {this.state.errors["type_of_major"] !== "" ? (
                    <div>
                      <Typography variant="caption" style={{ color: "red" }}>
                        {this.state.errors["type_of_major"]}
                      </Typography>
                    </div>
                  ) : null}
                  <Typography variant="subtitle2">
                    Type of Course <span className="red-alert">*</span>
                  </Typography>
                  <select
                    className="form-control"
                    type="text"
                    name="type_of_major"
                    required
                    value={this.state.type_of_major}
                    onChange={this.onChange}
                  >
                    <option value="">Select</option>

                    <option value={`1M2m`}>1 Major 2 Minor</option>
                    <option value={`3m`}>3 Minor</option>
                  </select>
                </Grid>
              )}
              <Grid item xs={12} sm={12} lg={3} md={3}>
                {this.state.errors["exam_type"] !== "" ? (
                  <div>
                    <Typography variant="caption" style={{ color: "red" }}>
                      {this.state.errors["exam_type"]}
                    </Typography>
                  </div>
                ) : null}
                <Typography variant="subtitle2">
                  Examination Type <span className="red-alert">*</span>
                </Typography>
                <select
                  className="form-control"
                  type="text"
                  name="exam_type"
                  required
                  value={this.state.exam_type}
                  onChange={this.onChange}
                >
                  <option value="">Select</option>
                  {["INTERNAL", "SESSIONAL", "PRACTICAL", "SEMESTER"].map(
                    (el) => (
                      <option value={el}>{el}</option>
                    )
                  )}
                </select>
              </Grid>

              <Grid item xs={12} sm={12} lg={3} md={3}>
                {this.state.errors["course"] !== "" ? (
                  <div>
                    <Typography variant="caption" style={{ color: "red" }}>
                      {this.state.errors["course"]}
                    </Typography>
                  </div>
                ) : null}
                <Typography variant="subtitle2">
                  Course <span className="red-alert">*</span>
                </Typography>
                <select
                  className="form-control"
                  type="text"
                  name="course"
                  required
                  value={this.state.course}
                  onChange={this.onChange}
                >
                  <option value="">Select</option>
                  {this.state.courses
                    .filter((el) =>
                      ["BA", "BSC", "BSCIT", "BBA", "PG"].includes(el.name)
                    )
                    .map((el) => (
                      <option value={el.name}>{el.label}</option>
                    ))}
                </select>
              </Grid>

              {this.state.course === "PG" && (
                <Grid item xs={12} sm={12} lg={3} md={3}>
                  {this.state.errors["dept_code"] !== "" ? (
                    <div>
                      <Typography variant="caption" style={{ color: "red" }}>
                        {this.state.errors["dept_code"]}
                      </Typography>
                    </div>
                  ) : null}

                  <Typography variant="subtitle2">
                    Department <span className="red-alert">*</span>
                  </Typography>
                  <select
                    className="form-control"
                    type="text"
                    name="dept_code"
                    required
                    value={this.state.dept_code}
                    onChange={this.onChange}
                  >
                    <option value="">Select</option>
                    {this.state.departments
                      .filter((el) =>
                        ["BOT", "CHM", "ZOO"].includes(el.dept_code)
                      )
                      .map((el) => (
                        <option value={el.dept_code}>{el.dept_name}</option>
                      ))}
                  </select>
                </Grid>
              )}

              <Grid item xs={12} sm={12} lg={3} md={3}>
                {this.state.errors["session_appearing"] !== "" ? (
                  <div>
                    <Typography variant="caption" style={{ color: "red" }}>
                      {this.state.errors["session_appearing"]}
                    </Typography>
                  </div>
                ) : null}
                <Typography variant="subtitle2">
                  Session <span className="red-alert">*</span>
                </Typography>
                <select
                  className="form-control"
                  type="text"
                  name="session_appearing"
                  required
                  value={this.state.session_appearing}
                  onChange={this.onChange}
                >
                  <option value="">Select</option>
                  {this.state.sessions.map((el) => (
                    <option value={el.id}>{el.session_name}</option>
                  ))}
                </select>
              </Grid>

              <Grid item xs={12} sm={12} lg={3} md={3}>
                {this.state.errors["exam_appearing"] !== "" ? (
                  <div>
                    <Typography variant="caption" style={{ color: "red" }}>
                      {this.state.errors["exam_appearing"]}
                    </Typography>
                  </div>
                ) : null}
                <Typography variant="subtitle2">
                  Examination Appearing <span className="red-alert">*</span>
                </Typography>
                <select
                  className="form-control"
                  type="text"
                  name="exam_appearing"
                  required
                  value={this.state.exam_appearing}
                  onChange={this.onChange}
                >
                  <option value="">Select</option>
                  {this.state.semesters.map((el) => (
                    <option value={el}>{getGoodSemester(el)}</option>
                  ))}
                </select>
              </Grid>

              {(this.state.course === "BA" ||
                this.state.course === "BSC" ||
                this.state.course === "BBA" ||
                this.state.course === "BSCIT" ||
                this.state.course === "PG") && (
                <Grid item xs={12} sm={12} lg={3} md={3}>
                  {this.state.errors["college_roll_no"] !== "" ? (
                    <div>
                      <Typography variant="caption" style={{ color: "red" }}>
                        {this.state.errors["college_roll_no"]}
                      </Typography>
                    </div>
                  ) : null}

                  <Typography variant="subtitle2">
                    College Roll No <span className="red-alert">*</span>
                  </Typography>
                  <input
                    className="form-control"
                    type="text"
                    name="college_roll_no"
                    required
                    value={this.state.college_roll_no}
                    onChange={this.onChange}
                    placeholder="Enter College Roll No"
                  />
                </Grid>
              )}

              {(this.state.course === "BA" ||
                this.state.course === "BSC" ||
                this.state.course === "BBA" ||
                this.state.course === "BSCIT" ||
                this.state.course === "PG") && (
                <Grid item xs={12} sm={12} lg={3} md={3}>
                  {/* {this.state.errors['university_roll_no'] !== '' ? (

                                    <div>
                                        <Typography
                                            variant="caption"
                                            style={{ color: 'red' }}
                                        >
                                            {this.state.errors['university_roll_no']}
                                        </Typography>
                                    </div>

                                ) : null} */}

                  <Typography variant="subtitle2">
                    G. U Roll & No{" "}
                    {/* G.U Roll & No <span className="red-alert">*</span> */}
                  </Typography>
                  <input
                    className="form-control"
                    type="text"
                    name="university_roll_no"
                    value={this.state.university_roll_no}
                    onChange={this.onChange}
                    placeholder="Enter G.U Roll No"
                  />
                </Grid>
              )}

              {this.state.course_main_type == "CBCS" &&
                (this.state.course === "BA" ||
                  this.state.course === "BSC" ||
                  this.state.course === "BBA" ||
                  this.state.course === "BSCIT" ||
                  this.state.course === "PG") &&
                this.state.exam_appearing !== "" &&
                this.state.exam_appearing != null &&
                this.state.exam_appearing != 0 && (
                  <Grid item xs={12} sm={12} lg={12} md={12}>
                    {this.state.errors["papers"] !== "" ? (
                      <div>
                        <Typography variant="caption" style={{ color: "red" }}>
                          {this.state.errors["papers"]}
                        </Typography>
                      </div>
                    ) : null}

                    <Typography variant="subtitle2">
                      Select Papers (Only those for which you want to appear in
                      examination) <span className="red-alert">*</span>
                    </Typography>
                    <Select
                      allowClear
                      showSearch
                      mode="multiple"
                      style={{ width: "100%" }}
                      onChange={(value) =>
                        this.setState({
                          papers_selected: !!value ? value : [],
                        })
                      }
                      value={this.state.papers_selected}
                      filterOption={(input, option) =>
                        (option != null && option != "" ? option.label : "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                    >
                      <Option key={0} value={``} disabled label={``}>
                        Select
                      </Option>

                      {this.state.f_papers.map((el, idx) => (
                        <Option
                          key={idx + 1}
                          value={el.paper_code}
                          label={`${el.paper_code} :: ${el.paper_name} (${el.sub_name})`}
                        >
                          {el.paper_code} :: {el.paper_name} ({el.sub_name})
                        </Option>
                      ))}
                    </Select>
                  </Grid>
                )}
            </Grid>

            {this.state.course_main_type == "NEP" && <Grid container spacing={16}>


              {this.state.type_of_major == "1M2m" && <>
              <Grid item xs={12} sm={12} lg={3} md={3}>
                {this.state.errors["major_1"] !== "" ? (
                  <div>
                    <Typography variant="caption" style={{ color: "red" }}>
                      {this.state.errors["major_1"]}
                    </Typography>
                  </div>
                ) : null}
                <Typography variant="subtitle2">
                  Select Major <span className="red-alert">*</span>
                </Typography>
                <select
                  className="form-control"
                  type="text"
                  name="major_1"
                  required
                  value={this.state.major_1}
                  onChange={this.onChange}
                >
                  <option value="">Select</option>
                  {this.state.subjects.filter((el) => el.is_nep == "YES" && el.sub_type == "MAJOR").map((el) => (
                    <option value={el.sub_code}>{el.sub_name}</option>
                  ))}
                </select>
              </Grid>
              <Grid item xs={12} sm={12} lg={3} md={3}>
                {this.state.errors["minor_1"] !== "" ? (
                  <div>
                    <Typography variant="caption" style={{ color: "red" }}>
                      {this.state.errors["minor_1"]}
                    </Typography>
                  </div>
                ) : null}
                <Typography variant="subtitle2">
                  Select Minor 1 <span className="red-alert">*</span>
                </Typography>
                <select
                  className="form-control"
                  type="text"
                  name="minor_1"
                  required
                  value={this.state.minor_1}
                  onChange={this.onChange}
                >
                  <option value="">Select</option>
                  {this.state.subjects.filter((el) => el.is_nep == "YES" && el.sub_type == "MINOR").map((el) => (
                    <option value={el.sub_code}>{el.sub_name}</option>
                  ))}
                </select>
              </Grid>

              <Grid item xs={12} sm={12} lg={3} md={3}>
                {this.state.errors["minor_2"] !== "" ? (
                  <div>
                    <Typography variant="caption" style={{ color: "red" }}>
                      {this.state.errors["minor_2"]}
                    </Typography>
                  </div>
                ) : null}
                <Typography variant="subtitle2">
                  Select Minor 2 <span className="red-alert">*</span>
                </Typography>
                <select
                  className="form-control"
                  type="text"
                  name="minor_2"
                  required
                  value={this.state.minor_2}
                  onChange={this.onChange}
                >
                  <option value="">Select</option>
                  {this.state.subjects.filter((el) => el.is_nep == "YES" && el.sub_type == "MINOR").map((el) => (
                    <option value={el.sub_code}>{el.sub_name}</option>
                  ))}
                </select>
              </Grid>
              </>}


              {this.state.type_of_major == "3m" && <>
              <Grid item xs={12} sm={12} lg={3} md={3}>
                {this.state.errors["minor_1"] !== "" ? (
                  <div>
                    <Typography variant="caption" style={{ color: "red" }}>
                      {this.state.errors["minor_1"]}
                    </Typography>
                  </div>
                ) : null}
                <Typography variant="subtitle2">
                  Select Minor 1 <span className="red-alert">*</span>
                </Typography>
                <select
                  className="form-control"
                  type="text"
                  name="minor_1"
                  required
                  value={this.state.minor_1}
                  onChange={this.onChange}
                >
                  <option value="">Select</option>
                  {this.state.subjects.filter((el) => el.is_nep == "YES" && el.sub_type == "MINOR").map((el) => (
                    <option value={el.sub_code}>{el.sub_name}</option>
                  ))}
                </select>
              </Grid>
              <Grid item xs={12} sm={12} lg={3} md={3}>
                {this.state.errors["minor_2"] !== "" ? (
                  <div>
                    <Typography variant="caption" style={{ color: "red" }}>
                      {this.state.errors["minor_2"]}
                    </Typography>
                  </div>
                ) : null}
                <Typography variant="subtitle2">
                  Select Minor 2 <span className="red-alert">*</span>
                </Typography>
                <select
                  className="form-control"
                  type="text"
                  name="minor_2"
                  required
                  value={this.state.minor_2}
                  onChange={this.onChange}
                >
                  <option value="">Select</option>
                  {this.state.subjects.filter((el) => el.is_nep == "YES" && el.sub_type == "MINOR").map((el) => (
                    <option value={el.sub_code}>{el.sub_name}</option>
                  ))}
                </select>
              </Grid>

              <Grid item xs={12} sm={12} lg={3} md={3}>
                {this.state.errors["minor_3"] !== "" ? (
                  <div>
                    <Typography variant="caption" style={{ color: "red" }}>
                      {this.state.errors["minor_3"]}
                    </Typography>
                  </div>
                ) : null}
                <Typography variant="subtitle2">
                  Select Minor 3 <span className="red-alert">*</span>
                </Typography>
                <select
                  className="form-control"
                  type="text"
                  name="minor_3"
                  required
                  value={this.state.minor_3}
                  onChange={this.onChange}
                >
                  <option value="">Select</option>
                  {this.state.subjects.filter((el) => el.is_nep == "YES" && el.sub_type == "MINOR").map((el) => (
                    <option value={el.sub_code}>{el.sub_name}</option>
                  ))}
                </select>
              </Grid>
              </>}
              

              

              


              <Grid item xs={12} sm={12} lg={3} md={3}>
                {this.state.errors["mdc"] !== "" ? (
                  <div>
                    <Typography variant="caption" style={{ color: "red" }}>
                      {this.state.errors["mdc"]}
                    </Typography>
                  </div>
                ) : null}
                <Typography variant="subtitle2">
                  MDC <span className="red-alert">*</span>
                </Typography>
                <select
                  className="form-control"
                  type="text"
                  name="mdc"
                  required
                  value={this.state.mdc}
                  onChange={this.onChange}
                >
                  <option value="">Select</option>
                  {this.state.subjects.filter((el) => el.is_nep == "YES" && el.sub_type == "MDC").map((el) => (
                    <option value={el.sub_code}>{el.sub_name}</option>
                  ))}
                </select>
              </Grid>

              <Grid item xs={12} sm={12} lg={3} md={3}>
                {this.state.errors["vac"] !== "" ? (
                  <div>
                    <Typography variant="caption" style={{ color: "red" }}>
                      {this.state.errors["vac"]}
                    </Typography>
                  </div>
                ) : null}
                <Typography variant="subtitle2">
                  VAC <span className="red-alert">*</span>
                </Typography>
                <select
                  className="form-control"
                  type="text"
                  name="vac"
                  required
                  value={this.state.vac}
                  onChange={this.onChange}
                >
                  <option value="">Select</option>
                  {this.state.subjects.filter((el) => el.is_nep == "YES" && el.sub_type == "VAC").map((el) => (
                    <option value={el.sub_code}>{el.sub_name}</option>
                  ))}
                </select>
              </Grid>

              <Grid item xs={12} sm={12} lg={3} md={3}>
                {this.state.errors["aec"] !== "" ? (
                  <div>
                    <Typography variant="caption" style={{ color: "red" }}>
                      {this.state.errors["aec"]}
                    </Typography>
                  </div>
                ) : null}
                <Typography variant="subtitle2">
                  AEC <span className="red-alert">*</span>
                </Typography>
                <select
                  className="form-control"
                  type="text"
                  name="aec"
                  required
                  value={this.state.aec}
                  onChange={this.onChange}
                >
                  <option value="">Select</option>
                  {this.state.subjects.filter((el) => el.is_nep == "YES" && el.sub_type == "AEC").map((el) => (
                    <option value={el.sub_code}>{el.sub_name}</option>
                  ))}
                </select>
              </Grid>

              <Grid item xs={12} sm={12} lg={3} md={3}>
                {this.state.errors["sec"] !== "" ? (
                  <div>
                    <Typography variant="caption" style={{ color: "red" }}>
                      {this.state.errors["sec"]}
                    </Typography>
                  </div>
                ) : null}
                <Typography variant="subtitle2">
                  SEC <span className="red-alert">*</span>
                </Typography>
                <select
                  className="form-control"
                  type="text"
                  name="sec"
                  required
                  value={this.state.sec}
                  onChange={this.onChange}
                >
                  <option value="">Select</option>
                  {this.state.subjects.filter((el) => el.is_nep == "YES" && el.sub_type == "SEC").map((el) => (
                    <option value={el.sub_code}>{el.sub_name}</option>
                  ))}
                </select>
              </Grid>
            </Grid>}
          </Paper>
          <br />

          <br />
          <CardActions className={classes.cardActions}>
            <Button
              variant="contained"
              className={classes.buttonSubmit}
              type="submit"
            >
              Save &amp; Proceed to next step
            </Button>
          </CardActions>
        </form>
      </div>
    );
  }
}

export default withStyles(styles)(FormOne);
