import React, { Component } from 'react'
import { withStyles, Button, Typography, Grid, Divider, CircularProgress } from '@material-ui/core';
import { GetData } from '../../../api/service';
import './formprint.css'
import AlertDiv from '../Forms/AlertDiv';
import { grey } from '@material-ui/core/colors';
import Loading from '../Forms/Loading';


const styles = theme => ({

})

class PreviewForm extends Component {

    state = {
        data: [],
        isLoaded: false
    }

    getInitialData = () => {
        GetData(`/${this.props.user.email}/admissionform/getactivestudentsingle`)
            .then((resp) => {

                if (Array.isArray(resp) && resp.length > 0) {
                    this.setState({
                        data: resp[0],
                        isLoaded: true
                    })
                }
            })
    }

    componentDidMount() {
        this.getInitialData();
    }

    render() {
        let { classes } = this.props;
        return (
            <div>


                {this.state.isLoaded ? <AdmissionForm
                    {...this.state.data}
                /> :
                    <Loading />
                }


            </div>
        )
    }
}

export default withStyles(styles)(PreviewForm);

class AdmissionForm extends Component {
    render() {
        let total_marks = 0;
        let marks_secured = 0;
        return (
            <div className="admission-form">



                <Grid container spacing={24}>

                    <Grid item lg={9} md={9} sm={9} xs={9}>
                        <table width="100%">
                            <tbody>
                                <tr>
                                    <td width="40%">
                                        <Typography

                                        >
                                            Class to which admission is sougth
                                </Typography>
                                    </td>
                                    <td>
                                        <Typography>
                                            :
                                </Typography>
                                    </td>
                                    <td>
                                        {this.props.no_combo_course === 0 ? (<Typography
                                            variant="body2"
                                        >
                                            {this.props.course}-{this.props.stream}-1st {this.props.course === 'HS' ? `Year` : `Semester`} - {this.props.course_type}
                                        </Typography>) : (
                                                <div>
                                                    {this.props.course != 'PG' ? (
                                                        <Typography
                                                            variant="body2"
                                                        >
                                                            {this.props.course}-1st {this.props.course === 'HS' ? `Year` : `Semester`}
                                                        </Typography>
                                                    ) : (
                                                            <Typography
                                                                variant="body2"
                                                            >
                                                                {this.props.stream == 'ARTS' ? 'M.A' : 'M.Sc.'}-1st Semester
                                                            </Typography>
                                                        )}
                                                </div>

                                            )}
                                    </td>
                                </tr>

                                {/* <tr>
                                    <td width="40%">
                                        <Typography

                                        >
                                            Do you want to seek free admission ?
                                </Typography>
                                    </td>
                                    <td>
                                        <Typography>
                                            :
                                </Typography>
                                    </td>
                                    <td>
                                        <Typography
                                            variant="body2"
                                        >
                                            {this.props.is_free_admission === 0 ? 'No' : 'Yes'}
                                        </Typography>
                                    </td>
                                </tr> */}
                                <tr vAlign="bottom">
                                    <td colSpan="2">
                                        <table width="100%" className="table">
                                            <tbody>
                                                <tr>
                                                    <td width="50%">
                                                        <Typography>
                                                            Marks Obtained
                                                </Typography>
                                                    </td>
                                                    <td>
                                                        <Typography>
                                                            {this.props.course != 'PG' ? this.props.marks_obtained : null}
                                                            {this.props.course == 'PG' ? this.props.ranking_score : null}
                                                        </Typography>
                                                    </td>
                                                </tr>
                                                {this.props.course != 'PG' ? (<tr>
                                                    <td width="50%">
                                                        <Typography>
                                                            Total Marks
                                                </Typography>
                                                    </td>
                                                    <td>
                                                        <Typography>
                                                            {this.props.total_marks}
                                                        </Typography>
                                                    </td>
                                                </tr>) : (
                                                        <tr>
                                                            <td width="50%">
                                                                <Typography>
                                                                    Roll No
                                                </Typography>
                                                            </td>
                                                            <td>
                                                                <Typography>
                                                                    {this.props.university_roll_no}
                                                                </Typography>
                                                            </td>
                                                        </tr>
                                                    )}
                                            </tbody>
                                        </table>
                                    </td>

                                    <td colSpan="2">
                                        <table width="100%" className="table">
                                            <tbody>



                                                {this.props.course != 'PG' ? (<tr>
                                                    <td width="50%">
                                                        <Typography>
                                                            Percentage
                                                </Typography>
                                                    </td>
                                                    <td>
                                                        <Typography>
                                                            {this.props.percentage}
                                                        </Typography>
                                                    </td>
                                                </tr>) : (
                                                        <tr>
                                                            <td width="50%">
                                                                <Typography>
                                                                    Ranking Serial
                                                </Typography>
                                                            </td>
                                                            <td>
                                                                <Typography>
                                                                    {this.props.ranking_serial}
                                                                </Typography>
                                                            </td>
                                                        </tr>
                                                    )}
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                    </Grid>

                    <Grid item lg={3} md={3} sm={3} xs={3} align="center">
                        <div className="passport-photo">
                            <ImageViewer
                                data={this.props.dp}
                            />
                        </div>

                    </Grid>

                </Grid>


                <table width="100%" className="table">
                    <tbody>
                        <tr>
                            <td width="10%" >
                                <Typography>
                                    Name of the Applicant
                                </Typography>
                            </td>
                            <td width="15%">
                                <Typography
                                    variant="body2"
                                    style={{ fontSize: '16px' }}
                                >
                                    {this.props.name}
                                </Typography>
                            </td>
                            <td width="10%" >
                                <Typography>
                                    Phone
                                </Typography>
                            </td>
                            <td width="15%">
                                <Typography
                                    variant="body2"
                                    style={{ fontSize: '16px' }}
                                >
                                    {this.props.phone}
                                </Typography>
                            </td>

                            <td width="10%">
                                <Typography>
                                    Email
                                </Typography>
                            </td>
                            <td width="15%">
                                <Typography
                                    variant="body2"
                                    style={{ fontSize: '16px' }}
                                >
                                    {this.props.email}
                                </Typography>
                            </td>
                        </tr>
                        <tr>
                            <td width="10%" >
                                <Typography>
                                    Father's Name
                                </Typography>
                            </td>
                            <td width="15%">
                                <Typography
                                    variant="body2"
                                    style={{ fontSize: '16px' }}
                                >
                                    {this.props.father_name}
                                </Typography>
                            </td>
                            <td>

                                <Typography>
                                    Parent's Phone
</Typography>
                            </td>
                            <td>
                                <Typography
                                    variant="body2"
                                    style={{ fontSize: '16px' }}
                                >
                                    {this.props.father_phone}
                                </Typography>
                            </td>
                            <td>

                                <Typography>
                                    Parent's Email
</Typography>
                            </td>
                            <td>
                                <Typography
                                    variant="body2"
                                    style={{ fontSize: '16px' }}
                                >
                                    {this.props.father_email}
                                </Typography>
                            </td>


                        </tr>
                        <tr>
                            <td>

                                <Typography>
                                    Mother's Name
</Typography>
                            </td>
                            <td>
                                <Typography
                                    variant="body2"
                                    style={{ fontSize: '16px' }}
                                >
                                    {this.props.mother_name}
                                </Typography>
                            </td>
                            <td>

                                <Typography>
                                    Mother's Phone
</Typography>
                            </td>
                            <td>
                                <Typography
                                    variant="body2"
                                    style={{ fontSize: '16px' }}
                                >
                                    {this.props.mother_phone}
                                </Typography>
                            </td>
                            <td >
                                <Typography>
                                    Gender
                                </Typography>
                            </td>
                            <td>
                                <Typography
                                    variant="body2"
                                    style={{ fontSize: '16px' }}
                                >
                                    {this.props.gender}
                                </Typography>
                            </td>
                        </tr>
                        <tr>


                            <td >
                                <Typography>
                                    Caste
                                </Typography>
                            </td>
                            <td>
                                <Typography
                                    variant="body2"
                                    style={{ fontSize: '16px' }}
                                >
                                    {this.props.caste}
                                </Typography>
                            </td>

                            <td >
                                <Typography>
                                    Date of Birth
                                </Typography>
                            </td>
                            <td>
                                <Typography
                                    variant="body2"
                                    style={{ fontSize: '16px' }}
                                >
                                    {this.props.date_of_birth}
                                </Typography>
                            </td>
                            <td >
                                <Typography>
                                    Religion
                                </Typography>
                            </td>
                            <td >
                                <Typography
                                    variant="body2"
                                    style={{ fontSize: '16px' }}
                                >
                                    {this.props.religion}
                                </Typography>
                            </td>
                        </tr>

                        <tr>


                            <td >
                                <Typography>
                                    Domicile State
                                </Typography>
                            </td>
                            <td>
                                <Typography
                                    variant="body2"
                                    style={{ fontSize: '16px' }}
                                >
                                    {this.props.state}
                                </Typography>
                            </td>

                            <td >
                                <Typography>
                                    Nationality
                                </Typography>
                            </td>
                            <td>
                                <Typography
                                    variant="body2"
                                    style={{ fontSize: '16px' }}
                                >
                                    {this.props.nationality}
                                </Typography>
                            </td>
                            <td>
                                <Typography>
                                    Blood Group
                                </Typography>
                            </td>
                            <td>
                                <Typography
                                    variant="body2"
                                    style={{ fontSize: '16px' }}
                                >
                                    {this.props.blood_group}
                                </Typography>
                            </td>
                        </tr>


                        <tr>

                            <td >
                                <Typography>
                                    Parent's / Guardian's Occupation
                                </Typography>
                            </td>
                            <td>
                                <Typography
                                    variant="body2"
                                    style={{ fontSize: '16px' }}
                                >
                                    {this.props.p_occupation}
                                </Typography>
                            </td>
                            <td>
                                <Typography>
                                    Extra-curricular Activity
                                </Typography>
                            </td>
                            <td>
                                <Typography
                                    variant="body2"
                                    style={{ fontSize: '16px' }}
                                >
                                    {this.props.extra_curr}
                                </Typography>
                            </td>
                            <td >
                                <Typography>
                                    Mother Tongue
                                </Typography>
                            </td>
                            <td>
                                <Typography
                                    variant="body2"
                                    style={{ fontSize: '16px' }}
                                >
                                    {this.props.mother_toungue}
                                </Typography>
                            </td>
                        </tr>
                        <tr>

                            <td>
                                <Typography>
                                    Languages Known
                                </Typography>
                            </td>
                            <td>
                                <Typography
                                    variant="body2"
                                    style={{ fontSize: '16px' }}
                                >
                                    {this.props.languages_known}
                                </Typography>
                            </td>
                            <td>
                                <Typography>
                                    Annual Income of Parents
                                </Typography>
                            </td>
                            <td>
                                <Typography
                                    variant="body2"
                                    style={{ fontSize: '16px' }}
                                >
                                    {this.props.annual_income}
                                </Typography>
                            </td>
                        </tr>


                    </tbody>
                </table>

                <table width="100%" className="table">
                    <tbody>
                        <tr>
                            <td>
                                <Typography>
                                    Examination Passsed
                                </Typography>
                            </td>
                            <td align="center">
                                <Typography>
                                    Board / University
                                </Typography>
                            </td>
                            <td align="center">
                                <Typography>
                                    Roll &amp; No.
                                </Typography>
                            </td>
                            <td align="center">
                                <Typography>
                                    Year
                                </Typography>
                            </td>
                            <td align="center">
                                <Typography>
                                    Institution
                                </Typography>
                            </td>
                        </tr>
                        {this.props.exam_passed.map((el, index) =>

                            <tr key={index}>
                                <td align="center">{el.examination_passed}</td>
                                <td align="center">{el.board} {el.board === 'OTHERS' ? `(${el.board_name})` : null}</td>
                                <td align="center">{el.roll}</td>
                                <td align="center">{el.year}</td>
                                <td align="center">{el.institution}</td>
                            </tr>

                        )}
                    </tbody>

                </table>

                {this.props.course === 'TDC' ? (

                    <div>
                        <table className="table" width="100%">
                            <tbody>
                                <tr>
                                    <td colSpan="5">
                                        <Typography>
                                            Best of three Science / Arts / Commerce Subjects
                                        </Typography>
                                    </td>
                                </tr>
                            </tbody>

                            <tbody>

                                <tr>
                                    <td>
                                        <Typography>
                                            Subjects
                                        </Typography>
                                    </td>
                                    {this.props.best_subjects.map((el, index) =>
                                        <td key={index} align="right">
                                            <Typography>
                                                {el.subject}
                                            </Typography>
                                        </td>
                                    )}
                                    <td align="right">
                                        <Typography>
                                            Total
                                        </Typography>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <Typography>
                                            Total Marks
                                        </Typography>
                                    </td>
                                    {this.props.best_subjects.map((el, index) => {
                                        total_marks = parseFloat(el.total_marks) + total_marks;
                                        return (
                                            <td key={index} align="right">
                                                <Typography>
                                                    {el.total_marks}
                                                </Typography>
                                            </td>
                                        )
                                    }

                                    )}
                                    <td align="right">
                                        <Typography>
                                            {total_marks}
                                        </Typography>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <Typography>
                                            Obtained Marks
                                        </Typography>
                                    </td>
                                    {this.props.best_subjects.map((el, index) => {
                                        marks_secured = parseFloat(el.marks_secured) + marks_secured;
                                        return (
                                            <td key={index} align="right">
                                                <Typography>
                                                    {el.marks_secured}
                                                </Typography>
                                            </td>
                                        )
                                    }
                                    )}
                                    <td align="right">
                                        <Typography>
                                            {marks_secured}
                                        </Typography>
                                    </td>
                                </tr>

                            </tbody>
                        </table>
                    </div>

                ) : null}


                {this.props.course != 'PG' ? (<div>
                    <table className="table" width="100%">
                        <tbody>
                            <tr>
                                <td colSpan={parseFloat(this.props.last_marks.length) + 2}>
                                    <Typography>
                                        Marks Secured in the last examination passed
                                        </Typography>
                                </td>
                            </tr>
                        </tbody>

                        <tbody>

                            <tr>
                                <td>
                                    <Typography>
                                        Subjects
                                        </Typography>
                                </td>
                                {this.props.last_marks.map((el, index) =>
                                    <td key={index} align="right">
                                        <Typography>
                                            {el.subject}
                                        </Typography>
                                    </td>
                                )}
                                <td align="right">
                                    <Typography>
                                        Total
                                        </Typography>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <Typography>
                                        Total Marks
                                        </Typography>
                                </td>
                                {this.props.last_marks.map((el, index) => {
                                    total_marks = parseFloat(el.total_marks) + total_marks;
                                    return (
                                        <td key={index} align="right">
                                            <Typography>
                                                {el.total_marks}
                                            </Typography>
                                        </td>
                                    )
                                }

                                )}
                                <td align="right">
                                    <Typography>
                                        {this.props.total_marks}
                                    </Typography>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <Typography>
                                        Obtained Marks
                                        </Typography>
                                </td>
                                {this.props.last_marks.map((el, index) => {
                                    marks_secured = parseFloat(el.marks_secured) + marks_secured;
                                    return (
                                        <td key={index} align="right">
                                            <Typography>
                                                {el.marks_secured}
                                            </Typography>
                                        </td>
                                    )
                                }
                                )}
                                <td align="right">
                                    <Typography>
                                        {this.props.marks_obtained}
                                    </Typography>
                                </td>
                            </tr>

                        </tbody>
                    </table>
                </div>) : null}

                <br />
                <br />

                {this.props.course === 'TDC' ? (<table width="100%" className="table">


                    {this.props.stream === 'ARTS' && this.props.course_type === 'HONOURS' ? (
                        <tbody>
                            <tr>
                                <td colSpan="4">
                                    <Typography>
                                        Combination of Subject sougth for TDC HONOURS
                                </Typography>
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    <Typography>
                                        Communication Subject: {this.props.mil.label}
                                    </Typography>
                                </td>
                                <td>
                                    <Typography variant="subtitle2">
                                        Honurs Preferences
                                        </Typography>
                                    <Typography>
                                        Preference 1: {this.props.major_1.label}
                                    </Typography>
                                    <Typography>
                                        Preference 2: {this.props.major_2.label}
                                    </Typography>
                                </td>

                                <td>
                                    <Typography variant="subtitle2">
                                        Generic Preferences
                                        </Typography>
                                    <Typography>
                                        Preference 1: {this.props.generic_1.label}
                                    </Typography>
                                    <Typography>
                                        Preference 2: {this.props.generic_2.label}
                                    </Typography>
                                </td>

                            </tr>

                        </tbody>
                    ) : null}


                    {this.props.stream === 'ARTS' && this.props.course_type === 'REGULAR' ? (
                        <tbody>
                            <tr>
                                <td colSpan="4">
                                    <Typography>
                                        Combination of Subject sougth for TDC REGULAR
                                </Typography>
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    <Typography>
                                        Communication Subject: {this.props.mil.label}
                                    </Typography>
                                </td>
                                <td colSpan={2}>
                                    <Typography variant="subtitle2">
                                        Regular Core
                                        </Typography>
                                    <Typography>
                                        1: {this.props.compulsory_1.label}
                                    </Typography>
                                    <Typography>
                                        2: {this.props.generic_1.label}
                                    </Typography>
                                    <Typography>
                                        3: {this.props.generic_2.label}
                                    </Typography>
                                </td>



                            </tr>

                        </tbody>
                    ) : null}


                    {this.props.stream === 'SCIENCE' && this.props.course_type === 'HONOURS' ? (
                        <tbody>
                            <tr>
                                <td colSpan="4">
                                    <Typography>
                                        Combination of Subject sougth for TDC HONOURS
                                </Typography>
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    <Typography>
                                        Communication Subject: {this.props.mil.label}
                                    </Typography>
                                </td>
                                <td>
                                    <Typography variant="subtitle2">
                                        Honurs Preferences
                                        </Typography>
                                    <Typography>
                                        Preference 1: {this.props.major_1.label}
                                    </Typography>
                                    <Typography>
                                        Preference 2: {this.props.major_2.label}
                                    </Typography>
                                </td>

                                <td>
                                    <Typography variant="subtitle2">
                                        Generic Preferences
                                        </Typography>
                                    <Typography>
                                        Preference 1: {this.props.generic_1.label}
                                    </Typography>
                                    <Typography>
                                        Preference 2: {this.props.generic_2.label}
                                    </Typography>
                                </td>

                            </tr>

                        </tbody>
                    ) : null}


                    {this.props.stream === 'SCIENCE' && this.props.course_type === 'REGULAR' ? (
                        <tbody>
                            <tr>
                                <td colSpan="4">
                                    <Typography>
                                        Combination of Subject sougth for TDC REGULAR
                                </Typography>
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    <Typography>
                                        Communication Subject: {this.props.mil.label}
                                    </Typography>
                                </td>
                                <td colSpan={2}>
                                    <Typography variant="subtitle2">
                                        Regular Core
                                        </Typography>
                                    <Typography>
                                        1: {this.props.generic_1.label}
                                    </Typography>
                                    <Typography>
                                        2: {this.props.generic_2.label}
                                    </Typography>
                                    <Typography>
                                        3: {this.props.generic_3.label}
                                    </Typography>
                                </td>



                            </tr>

                        </tbody>
                    ) : null}


                    {this.props.stream === 'COMMERCE' && this.props.course_type === 'HONOURS' ? (
                        <tbody>
                            <tr>
                                <td colSpan="4">
                                    <Typography>
                                        Combination of Subject sougth for TDC REGULAR
                                </Typography>
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    <Typography>
                                        Communication Subject: {this.props.mil.label}
                                    </Typography>
                                </td>
                                <td colSpan={2}>
                                    <Typography variant="subtitle2">
                                        Honours Core
                                        </Typography>
                                    <Typography>
                                        1: {this.props.compulsory_1.label}
                                    </Typography>
                                    <Typography>
                                        2: {this.props.compulsory_2.label}
                                    </Typography>
                                    <Typography>
                                        3: {this.props.generic_1.label}
                                    </Typography>
                                </td>



                            </tr>

                        </tbody>
                    ) : null}


                    {this.props.stream === 'COMMERCE' && this.props.course_type === 'REGULAR' ? (
                        <tbody>
                            <tr>
                                <td colSpan="4">
                                    <Typography>
                                        Combination of Subject sougth for TDC REGULAR
                                </Typography>
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    <Typography>
                                        Communication Subject: {this.props.mil.label}
                                    </Typography>
                                </td>
                                <td colSpan={2}>
                                    <Typography variant="subtitle2">
                                        Regular Core
                                        </Typography>
                                    <Typography>
                                        1: {this.props.compulsory_1.label}
                                    </Typography>
                                    <Typography>
                                        2: {this.props.compulsory_2.label}
                                    </Typography>
                                    <Typography>
                                        3: {this.props.compulsory_3.label}
                                    </Typography>
                                </td>



                            </tr>

                        </tbody>
                    ) : null}



                </table>) : null}



                {this.props.course === 'BCA' ? (<table width="100%" className="table">
                    <tbody>
                        <tr>
                            <td colSpan="4">
                                <Typography>
                                    Combination of Subject sougth for BCA
                                </Typography>
                            </td>
                        </tr>

                        <tr>
                            <td>
                                <Typography>
                                    AECC Subject: {this.props.mil.label}
                                </Typography>
                            </td>
                            <td colSpan={2}>
                                <Typography variant="subtitle2">
                                    Core Course
                                    </Typography>
                                <Typography>
                                    1: {this.props.compulsory_1.label}
                                </Typography>
                                <Typography>
                                    2: {this.props.compulsory_2.label}
                                </Typography>

                            </td>
                            <td>
                                <Typography variant="subtitle2">
                                    Generic Elective
                                    </Typography>
                                <Typography>
                                    1: {this.props.compulsory_3.label}
                                </Typography>
                            </td>
                        </tr>
                    </tbody>
                    
                </table>) : null}


                {this.props.course === 'BSC-BIOTECHNOLOGY' ? (<table width="100%" className="table">
                    <tbody>
                        <tr>
                            <td colSpan="4">
                                <Typography>
                                    Combination of Subject sougth for BSC-BIOTECHNOLOGY
                                </Typography>
                            </td>
                        </tr>

                        <tr>
                            <td>
                                <Typography>
                                    AECC Subject: {this.props.mil.label}
                                </Typography>
                            </td>
                            <td colSpan={2}>
                                <Typography variant="subtitle2">
                                    Core Course
                                    </Typography>
                                <Typography>
                                    1: {this.props.compulsory_1.label}
                                </Typography>
                                <Typography>
                                    2: {this.props.compulsory_2.label}
                                </Typography>

                            </td>
                            <td>
                                <Typography variant="subtitle2">
                                    Generic Elective
                                    </Typography>
                                <Typography>
                                    1: {this.props.compulsory_3.label}
                                </Typography>
                            </td>
                        </tr>
                    </tbody>
                    
                </table>) : null}





                {this.props.course === 'HS' ? (

                    <table width="100%" className="table">

                        <tbody>
                            <tr>
                                <td colSpan="4">
                                    <Typography>
                                        Combination of Subjects sougth for HS Course
                                </Typography>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <Typography
                                        variant="body2"
                                    >
                                        Compulsory: {this.props.compulsory_1.label}
                                    </Typography>
                                </td>
                                <td>
                                    <Typography
                                        variant="body2"
                                    >
                                        Compulsory: {this.props.compulsory_2.label}
                                    </Typography>
                                </td>
                                <td colSpan="2">
                                    <Typography
                                        variant="body2"
                                    >
                                        MIL: {this.props.mil.label}
                                    </Typography>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan="4">
                                    <Grid container spacing={16}>
                                        {this.props.optional_subjects.map((el, index) =>

                                            <Grid item lg={6} md={6} sm={6} xs={6} key={index}>
                                                <Typography variant="body2" >
                                                    {index + 1} : {el.label}
                                                </Typography>
                                            </Grid>

                                        )}
                                    </Grid>
                                </td>
                            </tr>
                        </tbody>

                    </table>) : null}

                {this.props.course === 'PG' ? (<table width="100%" className="table">

                    <tbody>
                        <tr>
                            <td colSpan="4">
                                <Typography>
                                    13. Subject taken: <b>{this.props.compulsory_1.label}</b>
                                </Typography>
                            </td>

                        </tr>



                    </tbody>
                </table>) : null}

                <br />


                <table className="table" width="100%">
                    <tbody>
                        <tr>
                            <td width="30%">
                                <Typography>
                                    15.   Permanent Address
                                </Typography>
                            </td>
                            <td width="70%">
                                <Typography
                                    variant="body2"
                                >
                                    {this.props.permanent_address}
                                </Typography>
                            </td>
                        </tr>

                        <tr>
                            <td width="30%">
                                <Typography>
                                    16.   Present Address
                                </Typography>
                            </td>
                            <td width="70%">
                                <Typography
                                    variant="body2"
                                >
                                    {this.props.present_address}
                                </Typography>
                            </td>
                        </tr>
                        <tr>
                            <td width="30%">
                                <Typography>
                                    17.   Local Guardian's Name
                                </Typography>
                            </td>
                            <td width="70%">
                                <Typography
                                    variant="body2"
                                >
                                    {this.props.local_g}
                                </Typography>
                            </td>
                        </tr>
                        <tr>
                            <td width="30%">
                                <Typography>
                                    18.   Extra-Curricular Activity
                                </Typography>
                            </td>
                            <td width="70%">
                                <Typography
                                    variant="body2"
                                >
                                    {this.props.extra_curr}
                                </Typography>
                            </td>
                        </tr>
                    </tbody>

                </table>





            </div>
        )
    }
}


class ImageViewer extends Component {
    state = {
        file_id: '',
        data: '',
        isLoaded: false
    }

    __getFile = (file_id) => {
        GetData(`/${file_id}/getfilesingle`)
            .then((resp) => {
                if (resp.hasOwnProperty('mime') && resp.hasOwnProperty('data')) {
                    let file = '';
                    if (resp.mime === 'image/jpeg' || resp.mime === 'image/png' || resp.mime === 'image/jpg') {
                        file = `data:${resp.mime};base64,${resp.data}`;
                    }
                    this.setState({
                        data: file,
                        isLoaded: true
                    })
                }
            })
    }

    componentDidMount() {
        let data = this.props.data;
        if (Array.isArray(data) && data.length > 0) {
            if (data[0].hasOwnProperty('file_id')) {
                this.__getFile(data[0].file_id)
            }
        }
    }

    render() {
        return (
            <div>
                {this.state.isLoaded ? <img
                    alt="sig-photo"
                    src={this.state.data}
                    style={{ width: '100px' }}

                /> : null}
            </div>
        )
    }
}