import React, { Component } from 'react'
import { withStyles } from '@material-ui/core';
import { blue } from '@material-ui/core/colors';
import ShowMdFiles from '../Essentials/ShowMdFiles';

const styles = theme => ({
    root: {
        backgroundColor: '#2f2f2f',
        paddingLeft: '10vw',
        paddingRight: '10vw',
        paddingTop: '5vh',
        paddingBottom: '5vh',
        backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),url('/images/main-banner.jpg')`,
        backgroundAttachment: 'fixed',
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center bottom",
        color: 'white'
    }
})

class Instructions extends Component {
  render() {
      let {classes} = this.props;
    return (
      <div className={classes.root}>
        <ShowMdFiles
          fileName={`examForm`}
        />
      </div>
    )
  }
}

export default withStyles(styles)(Instructions)