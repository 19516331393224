import React, { Component } from "react";
import Navbar from "../Components/Navbar";
import StudentDetailsLanding from "../Components/Homepage/Studentpersonal/StudentDetailsLanding";

class StudentDetailsContainer extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div>
        <StudentDetailsLanding history={this.props.history} {...this.props} />
      </div>
    );
  }
}

export default StudentDetailsContainer;
