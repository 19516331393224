import React, { Component } from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import history from './history';
import './App.css';
import Login from './auth_components/js/Login';
import HomePage from './Containers/HomePage';
import ForgotPasswordContainer from './Containers/ForgotPasswordContainer';
import RegistrationContainer from './Containers/RegistrationContainer';
import HomePageUser from './Containers/HomePageUser';
import ScrollToTop from './ScrollToTop';
// import ExamForm from './Components/ExamForm/ExamForm';
import ExamFormContainer from './Containers/ExamFormContainer';
import StudentDetailsContainer from './Containers/StudentDetailsContainer';
import StudentDetails from './Components/Homepage/Studentpersonal/StudentPersonalDetails/StudentDetails';
import AcademicDetailsContainer from './Containers/AcademicDetailsContainer';
import CoCurricularActivities from './Components/Homepage/Studentpersonal/StudentCoCurricularActivities/CoCurricularActivities';
import CoCurricularActivitiesContainer from './Containers/CoCurricularActivitiesContainer';


class App extends Component {
  render() {
    return (
      <Router history={history} >
        <div>
          <ScrollToTop>
            <Switch>
              <Route path="/login" component={Login} />
              <Route path="/forgotpassword" component={ForgotPasswordContainer} />
              <Route exact path="/" render={(props) => <HomePage {...props} />} />
              <Route exact path="/examformcontainer" render={(props) => <ExamFormContainer {...props} />} />
              {/* <Route exact path="/examform" render={(props) => <ExamForm {...props} />} /> */}
              {/* <Route path="student-details" component={StudentDetails}/> */}
              <Route exact path="/registration" render={(props) => <RegistrationContainer {...props} />} />
              <Route exact path="/student-details" render={(props) => <HomePageUser {...props} />} />
              <Route exact path="/academic-details" render={(props) => <HomePageUser {...props} />} />
              <Route exact path="/cocurricular-activities" render={(props) => < HomePageUser {...props} />} />
              <Route exact path="/home" render={(props) => <HomePageUser {...props} />} />
              <Route exact path="/final" render={(props) => <HomePageUser {...props} />} />
              <Route exact path="/exampaymentresponse" render={(props) => <HomePageUser {...props} />} />

            </Switch>
          </ScrollToTop>
        </div>
      </Router>
    );
  }
}

export default App;
