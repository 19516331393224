import React, { Component } from 'react'
import { withStyles } from '@material-ui/core';
import StudentDetails from './StudentPersonalDetails/StudentDetails';

const styles = theme => ({

})

class StudentDetailsLanding extends Component {
    render() {
        let { classes } = this.props;
        return (
            <div>
                <StudentDetails
                    history={this.props.history}
                    {...this.props}
                />
            </div>
        )
    }
}

export default withStyles(styles)(StudentDetailsLanding)