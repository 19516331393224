import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";
import withAuth from "../auth_components/js/withAuth";
import LoggedNavBar from "../Components/LoggedNavBar";
import FormsLanding from "../Components/Registration/Forms/FormsLanding";
import ViewForm from "../Components/Registration/View/ViewForm";
import PaymentResponse from "../Components/Registration/View/PaymentResponse";
import { Grid } from "@material-ui/core";
import { Menu } from "antd";
import StudentDetailsContainer from "./StudentDetailsContainer";
import AcademicDetailsContainer from "./AcademicDetailsContainer";
import CoCurricularActivitiesContainer from "./CoCurricularActivitiesContainer";

class HomePageUser extends Component {
  constructor(props) {
    super(props);
  }

  state = {
    currentPath: "",
  };

  componentDidMount() {
    this.setState({
      currentPath: this.props.history.location.pathname,
    });
  }

  onClick = (e) => {
    console.log("click ", e);
    this.props.history.push(e.key);
  };

  render() {
    return (
      <div>
        <LoggedNavBar history={this.props.history} type="new" />
        <div className="main-body">
          <Grid container spacing={24}>
            <Grid
              item
              xs={2}
              lg={2}
              sm={2}
              xs={2}
              style={{ backgroundColor: "#001529", minHeight: '80vh' }}
            >
              <Menu
                onClick={this.onClick}
                style={{ width: "100%" }}
                defaultSelectedKeys={[this.state.currentPath]}
                defaultOpenKeys={[this.state.currentPath]}
                theme="dark"
                mode="inline"
                items={[
                  {
                    label: "Examination Form Fillup",
                    key: "/home",
                  },
                  {
                    label: "Student Details",
                    key: "/student-details",
                  },
                  {
                    label: "Update Academic Details",
                    key: "/academic-details",
                  },
                  {
                    label: "Update Co-curricular Activities",
                    key: "/cocurricular-activities",
                  },
                ]}
              />
            </Grid>
            <Grid item xs={8} lg={8} sm={8} xs={8}>
              <Switch>
                <Route
                  exact
                  path="/home"
                  render={(props) => (
                    <FormsLanding
                      {...props}
                      history={this.props.history}
                      user={this.props.user.user}
                    />
                  )}
                />

                <Route
                  exact
                  path="/exampaymentresponse"
                  render={(props) => (
                    <PaymentResponse
                      {...props}
                      history={this.props.history}
                      user={this.props.user.user}
                    />
                  )}
                />

                <Route
                  exact
                  path="/student-details"
                  render={(props) => (
                    <StudentDetailsContainer
                      {...props}
                      history={this.props.history}
                      user={this.props.user.user}
                    />
                  )}
                />

                <Route
                  exact
                  path="/academic-details"
                  render={(props) => (
                    <AcademicDetailsContainer
                      {...props}
                      history={this.props.history}
                      user={this.props.user.user}
                    />
                  )}
                />

                <Route
                  exact
                  path="/cocurricular-activities"
                  render={(props) => (
                    <CoCurricularActivitiesContainer
                      {...props}
                      history={this.props.history}
                      user={this.props.user.user}
                    />
                  )}
                />
              </Switch>
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
}

export default withAuth(HomePageUser);
