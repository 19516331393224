import React, { Component } from 'react'
import { withStyles, Typography, Grid } from '@material-ui/core';
import { blue } from '@material-ui/core/colors';
import AccountBox from '@material-ui/icons/AccountBox'
import Assignment from '@material-ui/icons/Assignment'
import AccountBalanceWallet from '@material-ui/icons/AccountBalanceWallet'
import { APP_GOOD_NAME } from '../../Helper/GlobalVariable';

const styles = theme => ({
    root: {
        backgroundColor: '#2f2f2f',
        paddingLeft: '10vw',
        paddingRight: '10vw',
        paddingTop: '5vh',
        paddingBottom: '5vh',
        backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),url('/images/main-banner.jpg')`,
        backgroundAttachment: 'fixed',
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center bottom",
        
        
    }
})

class Procedure extends Component {
    render() {
        let { classes } = this.props;
        return (
            <div className={classes.root}>

                <Typography
                    variant="h3"
                    style={{ textAlign: 'center', color: 'white' }}
                    gutterBottom
                >
                    {APP_GOOD_NAME}
                </Typography>

                <Typography
                    variant="h5"
                    style={{textAlign: 'center', color: 'white'}}
                >
                    Welcome to Examination Portal (Internal & Sessional Examinations)
                </Typography>

                <br />

                <br />

                <Grid container spacing={24}>
                    <Grid item lg={4} md={4} xs={6} sm={6} style={{textAlign: 'center', color: 'white'}}>

                        <AccountBox style={{fontSize: 50, color: 'inherit'}} />
                        <Typography
                            color="inherit"
                            variant="body2"
                        >
                            Create Account
                        </Typography>
                    </Grid>

                    <Grid item lg={4} md={4} xs={6} sm={6} style={{ textAlign: 'center', color: 'white' }}>
                        <Assignment style={{ fontSize: 50, color: 'inherit' }} />
                        <Typography
                            color="inherit"
                            variant="body2"
                        >
                            Form Fill up &amp; Document Upload
                        </Typography>
                    </Grid>

                    <Grid item lg={4} md={4} xs={6} sm={6} style={{ textAlign: 'center', color: 'white' }}>
                        <AccountBalanceWallet style={{ fontSize: 50, color: 'inherit' }} />
                        <Typography
                            color="inherit"
                            variant="body2"
                        >
                            Final Submit
                        </Typography>
                    </Grid>
                </Grid>
            </div>
        )
    }
}

export default withStyles(styles)(Procedure)