import React, { Component } from "react";
import Navbar from "../Components/Navbar";
import StudentDetailsLanding from "../Components/Homepage/Studentpersonal/StudentDetailsLanding";
import StudentCoCurricularLanding from "../Components/Homepage/Studentpersonal/StudentCoCurricularLanding";

class CoCurricularActivitiesContainer extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div>
        <StudentCoCurricularLanding history={this.props.history} {...this.props} />
      </div>
    );
  }
}

export default CoCurricularActivitiesContainer;
