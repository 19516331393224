import React, { Component } from 'react'
import { withStyles, Grid, Typography } from '@material-ui/core';

const styles = theme => ({
    root: {
        backgroundColor: '#2f2f2f',
        color: 'white',
        paddingLeft: '10vw',
        paddingRight: '10vw',
        paddingTop: '5vh',
        paddingBottom: '5vh',
    }
})

class TermsPolicy extends Component {
    render() {
        let { classes } = this.props;
        return (
            <div className={classes.root}>
                <Grid container spacing={24}>

                    <Grid item lg={6} md={6} sm={12} xs={12}>
                        <Typography
                            color="inherit"
                            variant="h6"
                            gutterBottom
                        >
                            Contact Us
                        </Typography>

                        <Typography
                            color="inherit"
                            variant="body1"
                        >
                            For Any queries related to the process, please contact the office
                            <br />
                            Please contact us at (10 AM to 6PM - Working days): <br />
                            <a rel="noreferrer noopenner" target="_blank" href="telto:7002667386">70026 67386 (for course related issues)</a><br />
                            {/* <a rel="noreferrer noopenner" target="_blank" href="telto:8638201520">86382 01520 (for technical issues)</a><br />
                            <a rel="noreferrer noopenner" target="_blank" href="telto:7002197274">70021 97274 (for technical issues)</a><br /> */}
                        </Typography>
                    </Grid>

                    <Grid item lg={6} md={6} sm={12} xs={12}>
                        <Typography
                            color="inherit"
                            variant="h6"
                            gutterBottom
                        >
                            Other Links
                        </Typography>
                        <Typography
                            color="inherit"
                            variant="body1"
                        >
                           
                            {/* <a href={require('./../../assets/terms-and-conditions-pandu.pdf')} rel="noreferrer noopenner" target="_blank">Terms and Conditions</a> */}
                        </Typography>
                    </Grid>

                </Grid>

                <div style={{textAlign: 'right'}}>
                        <Typography style={{color: 'white'}}>Designed and Developed by <a href="https://www.corexx.in">corexx.in</a></Typography>
                </div>
            </div>
        )
    }
}

export default withStyles(styles)(TermsPolicy)