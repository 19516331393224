import React, { Component } from 'react';
import Navbar from '../Components/Navbar';
import './css/homepage.css';
import { GetData } from '../api/service';
import { Grid, Typography, Button } from '@material-ui/core';
import InstructionsDiv from '../Components/Homepage/InstructionsDiv';
import Login from '../auth_components/js/Login';
import NewLogin from '../Components/Homepage/NewLogin';
import Procedure from '../Components/Homepage/Procedure';
import Instructions from '../Components/Homepage/Instructions';
import TermsPolicy from '../Components/Homepage/TermsPolicy';
import { blue } from '@material-ui/core/colors';
import Grade from '@material-ui/icons/Grade'

class HomePage extends Component {
  constructor(props) {
    super(props);

    let colorRed1 = 'green';
    let colorRed2 = 'green';


    // if (new Date('06-07-2019').getTime() <= new Date().getTime()) {
    //   colorRed1 = 'red'
    // }

    // if (new Date('06-12-2019').getTime() <= new Date().getTime()) {
    //   colorRed2 = 'red'
    // }


    this.state = {
      colorRed1: colorRed1,
      colorRed2: colorRed2,
    }
  }

  render() {
    return (
      <div>
        <Navbar
          history={this.props.history}
        />

        <Procedure />


        <div className="main-body">

          <div>

            {/* Notification */}

            {/* <div>
              <marquee>
              <Typography style={{color: 'red'}}>
              <Grade style={{fontSize: 10}} />  Merit list will be uploaded in the college website. Date will be notified later. &nbsp;&nbsp;

              <Grade style={{fontSize: 10}} /> Date of Admission will be notified in the college website. &nbsp;&nbsp;

                <Grade style={{fontSize: 10}} /> Free Admission will be given as per Govt. &nbsp;&nbsp;

                <Grade  style={{fontSize: 10}}/> Free Admission scheme applicable to the students domicle from Assam only. &nbsp;&nbsp;
              </Typography>
              </marquee>
              
            </div> */}
             {/* <div>
            <Typography style={{color: 'green'}}>
              For help regarding User Id/Email Id , Date Of Birth <b>Whatsapp</b> in any of these numbers :<b>97068 46557 , 7002158110.</b> Don't forget to mention your Name , Class , Roll No.
            </Typography>
            </div> */}
            <br />

            <Grid container spacing={24}>
              <Grid item xs={12} sm={12} lg={6} md={6} >

                <InstructionsDiv />

              </Grid>

              <Grid item xs={12} sm={12} lg={6} md={6}>

                <NewLogin
                  history={this.props.history}
                />

              </Grid>


            </Grid>
          </div>



        </div>

        <Instructions />

        <TermsPolicy />

      </div>
    )
  }
}

export default HomePage;